@font-face {
  font-family: "helveticaneuebold";
  src: url("../fonts/helveticaneuebold.woff2") format("woff2"),
    url("../fonts/helveticaneuebold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helveticaneuemedium";
  src: url("../fonts/helveticaneue_med.woff2") format("woff2"),
    url("../fonts/helveticaneue_med.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HELVETICANEUERegular";
  src: url("../fonts/HELVETICANEUERegular.woff2") format("woff2"),
    url("../fonts/HELVETICANEUERegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helveticaneuelight";
  src: url("../fonts/helveticaneue-light_0.woff2") format("woff2"),
    url("../fonts/helveticaneue-light_0.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helvetica_neueextended";
  src: url("../fonts/helveticaneue-extended.woff2") format("woff2"),
    url("../fonts/helveticaneue-extended.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.fsreg {
  font-family: "HELVETICANEUERegular";
}

.fsmed {
  font-family: "helveticaneuemedium";
}

.fslight {
  font-family: "helveticaneuelight";
}

.textstyle p {
  font-family: "helveticaneuelight";
  color: #000;
  line-height: 23px;
  font-size: 16px;
}

::placeholder {
  color: #bfbfbf !important;
}

*,
html {
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "helveticaneuelight";
  color: #333537;
  overflow-x: hidden;
  box-sizing: border-box;
  background: #f6f4f9;
  font-size: 18px;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  outline: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

::-moz-selection {
  color: #ffffff;
  background: #110358;
}

::selection {
  color: #ffffff;
  background: #110358;
}

:focus {
  outline: none;
}

.form-control:focus {
  border-color: transparent;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0);
}

.mainPage {
  max-width: 576px;
  margin: auto;
  position: relative;
  min-height: 100vh;
}

.btn-primary {
  color: #fff;
  background-color: #5e2b97;
  border-color: #5e2b97;
  border-radius: 50px;
  font-size: 20px;
  padding: 10px 14px;
  font-family: "helveticaneuebold";
}

.setpinbtn {
  color: #fff;
  background-color: #5e2b97;
  border-color: #5e2b97;
  border-radius: 30px;
  font-size: 20px;
  padding: 10px 30px;
  font-family: "helveticaneuebold";
}

.setpinbtn:hover,
.setpinbtn:focus {
  /* color: #fff; */
  background-color: #5e2b97;
}

.pt80 {
  padding-top: 80px;
}

.headerfixed {
  width: 100%;
  position: fixed;
  top: 0;
  /* left: 0; */
  margin: 0;
  z-index: 9;
  padding: 26px 20px;
  height: 80px;
  max-width: 576px;
}

.arrowfixed {
  width: 100%;
  position: fixed;
  top: 0;
  /* left: 0;  */
  margin: 0;
  z-index: 1;
  padding: 30px 0px;
  height: 80px;
  max-width: 576px;
  background: #f6f4f9;
}

.btn-primary:hover,
.btn-primary:focus {
  color: #fff;
  background-color: #421d6c;
  outline: none;
}

.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #c7bfd1;
  border-radius: 0;
  height: 42px;
  font-size: 18px;
  font-family: "HELVETICANEUERegular";
}

.form-label {
  font-size: 18px;
  color: #4d4e50;
  font-family: "HELVETICANEUERegular";
}

.form-check-input:checked {
  background-color: #5e2b97;
  border-color: #5e2b97;
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0);
}

.labelborder {
  border-bottom: 1px solid #c7bfd1;
}

.inputIcon {
  position: relative;
}

.iconInputLeft {
  padding-left: 40px;
}

.icons {
  position: absolute;
  left: 8px;
  top: 5px;
}

.aNormalT {
  color: #5e2b97;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
  float: right;
}

.bordernone {
  border: 0px solid transparent;
}

/*Splash Screen-------*/
/* .videoOuter {
    width: 100%;
    margin-top: 80px;
    max-height: 80vh;
} */
.username {
  font-size: 20px;
  font-family: "helveticaneuemedium";
}

.videoOuter {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.headerOuter {
  width: 100%;
  object-fit: cover;
}

.splashBg {
  background: #662ea4;
  background: -moz-linear-gradient(top, #662ea4 0%, #492574 100%);
  background: -webkit-linear-gradient(top, #662ea4 0%, #492574 100%);
  background: linear-gradient(to bottom, #662ea4 0%, #492574 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#662ea4', endColorstr='#492574', GradientType=0);
  width: 100%;
  height: 100vh;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.splashBg .logo {
  text-align: center;
}

.splashBg .logo a img {
  width: 18rem;
}

.bottomLogo {
  text-align: center;
  position: fixed;
  /* left: 0;
	right: 0; */
  bottom: 0px;
  background: #e4e4e4;
  padding: 10px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
  width: 100%;
  max-width: 576px;
}

.bigpurplebottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 15px;
  padding-top: 5px;
  /* background: #fff; */
  /*background: rgba(0,0,0,0.03);*/
  /* border-top: 1px solid #e9e9e9; */
}

.coptrighttext {
  font-size: 12px;
  line-height: 18px;
  padding-top: 10px;
  margin-bottom: 0px;
  font-family: "Raleway", sans-serif;
  color: #707070;
}

.coptrighttext b {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  color: #707070;
}

/*Login Screen-------*/
.loginPage {
  padding: 2rem 1rem 3rem;
}

.loginLogo {
  text-align: center;
  margin: auto;
}

.loginLogo a img {
  width: 10rem;
}

.loginPage h1 {
  font-size: 34px;
  color: #000;
  margin-bottom: 40px;
  font-family: "HELVETICANEUERegular";
}

.loginPage h1 span {
  width: 100%;
  float: left;
  line-height: 40px;
  font-family: "helveticaneuebold";
}

.orLine {
  background: #5e2b97;
  height: 1px;
  width: 100%;
  position: relative;
  margin: 30px 0 40px 0;
}

.orLine span {
  background: #5e2b97;
  text-align: center;
  border-radius: 50%;
  height: 36px;
  font-size: 13px;
  font-family: "helveticaneuebold";
  width: 36px;
  color: #fff;
  line-height: 36px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: -18px;
  transform: translateX(-50%);
}

.normalBtn {
  border: 1px solid #5e2b97;
  color: #5e2b97;
  border-radius: 50px;
  display: flex;
  font-size: 14px;
  padding: 10px 14px;
  font-family: "helveticaneuemedium";
  align-items: center;
  font-weight: 400;
  justify-content: center;
  margin-bottom: 15px;
}

.normalBtn i {
  padding-left: 5px;
  width: 1.5rem;
}

.iconEye {
  position: absolute;
  right: 10px;
  top: 5px;
}

.pt80 .mainPage {
  min-height: calc(100vh - 80px) !important;
}

/*Header Top----*/
.headerTop {
  min-height: 99px;
  padding: 5px 0;
}

.backIconTop {
  text-align: left;
}

.profilebox1 {
  display: flex;
  width: 100%;
  /* margin: auto; */
  position: relative;
  /* justify-content: center; */
  align-items: center;
}

.userprofile1 {
  position: relative;
  /* margin-top: -9rem; */
  text-align: center;
  margin-bottom: 30px;
}

.editprofile1 {
  width: 50px;
  height: 50px;
  background: #5e2b97;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  right: -10px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*  Home Page   */
.Homeheaderbg {
  background: #5e2b97;
  padding: 30px 20px 35px;
}

.Homeheaderbg1 {
  margin-bottom: 35px;
  background: #5e2b97;
  padding: 30px 20px 35px;
}

.ghBottom {
  padding: 30px 20px 17px;
}

.customer-info {
  display: flex;
  color: #fff;
  padding-top: 30px;
  justify-content: space-between;
}

.customerimg img {
  width: 126px;
  height: 126px;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
  border: 1px solid #5e2b97;
}

.imgspace {
  margin-right: auto;
  text-align: right;
  position: relative;

  display: flex;
  justify-content: end;
}

.imgspace .imgsmall {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  top: 40px;
  right: -10px;
}

.imgspace .imgEditSmall {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  top: 40px;
  right: -10px;
}

.imgspace .imgbig {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  position: relative;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered h4 {
  font-size: 14px;
  line-height: 18px;
  font-family: "Raleway", sans-serif;
  padding-top: 10px;
  margin-bottom: 0px;
}

.boxposition {
  position: relative;
  margin: 10px auto;
}

/* .imgspace:after {
    content: '';
    display: block;
    background: url(../images/settings.svg) !important;
    width: 45px;
    height: 45px;
    position: absolute;
    right: -5px;
    top:40px;
	background-repeat:no-repeat;
	z-index:999;
} */

.customer-info h1 {
  font-size: 32px;
  font-family: "helveticaneuebold";
}

.customer-info h3 {
  font-size: 28px;
  font-family: "helveticaneuelight";
}

.contentbox {
  background: #fff;
  border-radius: 20px;
  position: relative;
  margin-top: -20px;
}

.mt0 {
  margin-top: 0px;
}

.parent {
  position: relative;
  background: #5e2b97;
  height: 25px;
}

.parentheight {
  height: auto !important;
}

.whitebg {
  background-color: #fff !important;
}

.fulogin {
  padding-top: 25px;
}

.fulogin h1 {
  font-size: 26px;
  line-height: 40px;
}

.fs36 {
  font-size: 34px;
  font-family: "helveticaneuelight";
}

.fs36 b {
  font-family: "helveticaneuemedium";
  font-weight: normal;
}

.arrowleft {
  padding-bottom: 30px;
}

.fmsignup h2 {
  font-family: "helveticaneuebold";
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: 400 !important;
}

.fs12 {
  font-size: 14px;
  color: #945f5f;
  font-family: "HELVETICANEUERegular";
}

.fsreg b {
  font-family: "helveticaneuebold";
  font-weight: normal;
}

.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #5e2b97;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 5px;
}

.form-group input:checked + label:before {
  background: #5e2b97;
}

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.clrblue {
  color: #5e2b97;
}

.fsbold {
  font-family: "helveticaneuebold";
}

.fmsignup .iconInputLeft {
  color: #bfbfbf;
}

.contentbodypadd {
  /* padding: 0px 2rem; */
}

.fs24 {
  font-size: 24px !important;
}

.media {
  padding: 0.7rem 15px;
  align-items: center;
  position: relative;
  width: 100%;
  position: relative;
  display: flex;
}

.media img.align-self-center {
  min-width: 55px;
  height: 55px;
  max-width: 55px;
  border-radius: 50%;
  border: 1px solid #5e2b97;
  object-fit: cover;
  margin-right: 10px;
}

.boxmediablue img.align-self-center {
  width: 50px;
  max-width: 50px;
  height: 55px;
  border-radius: 0px;
  border: 0px solid #5e2b97;
  object-fit: contain;
  margin-right: 10px;
}

.boxmediablue h5 {
  font-family: "helveticaneuelight" !important;
}

.boxmediablue h5 b {
  font-family: "helveticaneuebold";
  font-weight: normal;
}

.mediahead {
  font-family: "helveticaneuelight";
}

.mediahead b {
  font-family: "helveticaneuemedium";
  font-weight: normal;
}

.media h5 {
  margin-bottom: 0px;
  font-family: "helveticaneuemedium";
}

/*.media h5{font-family: 'helveticaneuemedium';}  */
.fsrlight {
  font-family: "helveticaneuelight" !important;
}

.fsrlight b {
  font-family: "helveticaneuemedium";
  font-weight: normal;
}

.mediaarrow {
  position: absolute;
  right: 20px;
  padding-right: 0px !important;
}

.contentboxgray {
  /* background: #f6f4f9; */
  border-radius: 20px 20px 0px 0px;
  position: relative;
  /* margin-top: -50px; */
}

.contentboxgray h2 {
  font-size: 20px;
  font-family: "HELVETICANEUERegular";
  text-align: left;
  padding-top: 10px;
}

.contentboxgray h2 b {
  font-family: "helveticaneuebold";
  font-weight: normal;
}

.btngreen {
  background: #23b97f;
  background: -moz-linear-gradient(right, #35c998 0%, #23b97f 100%);
  background: -webkit-linear-gradient(right, #35c998 0%, #23b97f 100%);
  background: linear-gradient(to right, #35c998 0%, #23b97f 100%);
  border: 1px solid #fff;
  font-family: "HELVETICANEUERegular";
}

.btngreen img {
  width: 30px;
}

/* .btnblue {
  background: #23b97f;
  background: -moz-linear-gradient(right, #5e2b97 0%, #2f164c 100%);
  background: -webkit-linear-gradient(right, #5e2b97 0%, #2f164c 100%);
  background: linear-gradient(to right, #5e2b97 0%, #2f164c 100%);
  border: 1px solid #fff;
  font-family: "HELVETICANEUERegular";
} */

.btnblue {
  background: #5e2b97;
  font-family: "HELVETICANEUERegular";
}

.btnblue img {
  width: 30px;
}

.contentbodypadd h4 {
  font-weight: bold;
  padding: 30px 0px;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.circlebox .form-group label {
  margin-bottom: 10px;
}

.circlebox .form-group label:before {
  border-radius: 50px;
}

/*
.arrowfixed{    position: fixed!important;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 0;
    z-index: 99999;}
	/*
	.arrowfixed.darkHeader {
		background-color: #fff;
		border-bottom: 1px solid #ddd;
		height:60px;
	}
	*/

.fs20 {
  font-size: 20px;
  font-family: "Raleway", sans-serif !important;
  padding-right: 23px;
}

.contentboxgray11 {
  background: #f6f4f9;
  border-radius: 20px 20px 0px 0px;
  position: relative;
  /* bottom: 20px; */
  /* z-index: 10; */
}

.contentboxgraynotfixedheader {
  background: #f6f4f9;
  border-radius: 20px 20px 0px 0px;
  position: relative;
  bottom: 20px;
  /* z-index: 10; */
}

.boxmediawhite {
  background: #fff;
  border-radius: 20px;
  margin: 0px 0px 12px;
}

.boxmediablue {
  background: #5e2b97;
  background: -moz-linear-gradient(right, #bd8ef2 0%, #5e2b97 100%);
  background: -webkit-linear-gradient(right, #bd8ef2 0%, #5e2b97 100%);
  background: linear-gradient(to right, #bd8ef2 0%, #5e2b97 100%);
  border-radius: 20px;
  margin: 0px 20px 20px;
  justify-content: center;
}

.contentbodymedia {
  padding: 0px 1.2rem 1rem;
}

.uploadbox {
  width: 120px;
  height: 120px;
  border-radius: 15px;
  background: #fff;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
}

.uploadbox p {
  font-size: 16px;
  color: #bfbfbf;
  font-family: "helveticaneuelight";
  line-height: 19px;
}

.uploadbox img {
  width: 30px;
  padding-bottom: 10px;
}

.formupload {
  margin-top: 20px;
  padding-bottom: 15px;
}

.btnwhite {
  background: #fff;
  color: #5e2b97;
  border: 1px solid #fff;
}

.mtminus {
  margin-top: -20px;
}

.welcome b {
  font-family: "helveticaneuebold";
  font-weight: normal;
}

/* FU- Video call  */
.darkgraybg {
  background: #4d4e50;
  padding-bottom: 50px;
}

/* .videobox3{outline: 2px solid #00838F; outline-offset: 10px; border-radius:50%;}
.videobox2{outline: 1px solid #00838F; outline-offset: 20px; border-radius:50%;}
.videobox1{outline: 1px solid rgba(0,131,143,0.5); outline-offset: 30px; border-radius:50%; } */
.videocallcircle {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  /* border: 1px solid #5e2b97; */
  object-fit: cover;
  position: relative;
}

.effect {
  height: 240px;
  width: 240px;
  /* outline: 1px solid rgba(0, 131, 143, 0.5); */
  position: relative;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: auto;
  border-radius: 50%;
  /* outline-offset: 24px; */
  display: flex;
  justify-content: center;
}

/* size pseudo within from coordonates */
/* .effect:before {
  content: "";
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  outline: 1px solid #00838f;
  position: absolute;
  border-radius: 50%;
  outline-offset: 18px;
}
.effect:after {
  content: "";
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
  outline: 2px solid #00838f;
  position: absolute;
  border-radius: 50%;
  outline-offset: 12px;
} */
.fs38 {
  font-size: 38px;
}

.calltext {
  display: inline-block;
  width: 320px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-family: "helveticaneuelight";
}

.circlecall {
  width: 100px;
  height: 100px;
  background: #fff;
  margin: auto;
  border-radius: 50%;
  border: 4px solid #d5d5d5;
  position: relative;
}

.circlecall:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%)
    hue-rotate(312deg) brightness(97%) contrast(117%);
}

.circlecall:after {
  content: "";
  position: absolute;
  top: 50%;
  /* position the top  edge of the element at the middle of the parent */
  left: 50%;
  /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, -50%);
  background: #d00e0e;
  background: -moz-linear-gradient(top, #ff4a4a 0%, #d00e0e 100%);
  background: -webkit-linear-gradient(top, #ff4a4a 0%, #d00e0e 100%);
  background: linear-gradient(to bottom, #ff4a4a 0%, #d00e0e 100%);
  z-index: -1;
  width: 145px;
  height: 145px;
  border-radius: 50%;
  border: 5px solid #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
}

.circlecall:hover {
  background: #d00e0e;
  background: -moz-linear-gradient(top, #d00e0e 0%, #fe4949 100%);
  background: -webkit-linear-gradient(top, #d00e0e 0%, #fe4949 100%);
  background: linear-gradient(to bottom, #d00e0e 0%, #fe4949 100%);
  border: 4px solid #fd4848;
}

.circlecall h4 {
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
}

.circlecall:hover h4 {
  color: #fff;
}

.circlecall {
  width: 100px;
  height: 100px;
  background: #fff;
  margin: auto;
  border-radius: 50%;
  border: 4px solid #d5d5d5;
  position: relative;
}

.calltime {
  font-size: 18px;
  font-family: "HELVETICANEUERegular" !important;
}

/* FU- Video call 2  */

.videouser {
  position: absolute;
  right: 10px;
  top: 10px;
}

.videouser img {
  width: 100px;
}

.bigimg {
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 534px;
}

.smallimg {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100px;
  object-fit: cover;
}

/* Search Bar  */
.searchbar input {
  border-bottom: 1px solid #d5d5d5;
  position: absolute;
}

input#searchicon {
  background-image: url("../images/search.svg");
  background-size: 20px 40px;
  background-repeat: no-repeat;
  text-indent: 15px;
  transition: 0.3s;
  position: relative;
  top: 10px;
}

input#searchicons:focus {
  background-image: none;
  text-indent: 0px;
}

/*  Fm Manage Photos   */
.imageupload img {
  margin-bottom: 20px;
  /* width: 100%; */
  height: 136px;
  object-fit: cover;
  cursor: pointer;
}

.nocontact img {
  width: 31%;
}

@media screen and (min-width: 480px) {
  .nocontact img {
    width: 21% !important;
  }
}

.fullimg {
  position: relative;
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullimg img {
  object-fit: cover;
  max-height: 350px;
  max-width: 100%;
  width: auto;
}

.fix-img-container {
  height: 350px;
  width: 100%;
}

/*  FM Add Image  */
textarea#msz {
  background: transparent;
  border: none;
  border-bottom: 1px solid #c7bfd1;
  border-radius: 0;
  width: 100%;
  font-size: 18px;
}

.inputIcon h5 {
  font-family: "helveticaneuemedium";
  padding-top: 30px;
  font-size: 22px;
}

.textred {
  color: #e21b29;
}

.textred:hover {
  color: #fff;
  background: #e21b29;
  border: 1px solid #e21b29;
}

/* View Image   */
.bigimgview {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.smallimgview {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 60px;
  cursor: pointer;
}

.wishlistbox {
  width: 85px;
  height: 85px;
  background: #fff;
  border-radius: 50%;
  margin: 40px auto 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/*  FU Settings  */
.settingbox {
  background: #fff;
  border-radius: 15px;
  padding: 10px 20px;
}

.settingbox ul li {
  font-size: 22px;
  padding: 10px 0px;
  border-bottom: 1px solid #f3f1f6;
  display: flex;
  align-items: center;
  font-family: "helveticaneuelight";
}

.settingbox ul li a {
  display: flex;
  align-items: center;
  font-family: "helveticaneuelight";
}

.settingbox ul li:last-child {
  border-bottom: none;
}

.settingbox ul li img {
  width: 45px;
  height: 45px;
  padding-right: 15px;
}

/*   My Profile   */
.userprofile {
  position: relative;
  margin-top: -9rem;
  text-align: center;
  margin-bottom: 20px;
}

.passwordbtn {
  background: #fff;
  border-radius: 10px;
  border: 1px solid #fff;
  margin-top: 30px;
  color: #000;
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
}

.passwordbtn:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%)
    hue-rotate(312deg) brightness(97%) contrast(117%);
}

.profilebox {
  display: flex;
  width: 100%;
  margin: auto;
  position: relative;
  justify-content: center;
  align-items: center;
}

/* Edit Profile */
.editprofile {
  width: 50px;
  height: 50px;
  background: #5e2b97;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  right: 0;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myprofile {
  width: 50px;
  height: 50px;
  background: #47db8f;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  right: 35%;
  bottom: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myprofileadmin {
  background: #f54c82;
}

.myprofile img {
  width: 32px;
}

.profileimg {
  width: 190px;
  height: 190px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  margin: auto;
  border: 1px solid #5e2b97;
}

/* 
.myprofile:after{ content: '';
    display: block;
    background: url(../images/selectFamilyAdminList.svg) #47DB8F no-repeat !important;
    width: 50px;
    height: 50px;
	border-radius:50%;
	border:2px solid #fff;
    position: absolute;
    right: 125px;
    bottom: -20px;
	background-repeat:no-repeat;
background-position:center;} */

/* Change Password  */

.fsregular {
  font-family: "helveticaneuelight";
}

h3.fsregular {
  font-size: 30px;
}

.support h4 {
  font-size: 22px;
  font-family: "HELVETICANEUERegular";
}

.support span img {
  width: 25px;
  height: 30px;
}

.videocallicon {
  width: 170px;
  margin: 60px auto 10px;
}

.fs32 {
  font-size: 32px !important;
}

/*   System Notification  */
.notificationbg {
  background-image: url(../images/notificationbg.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.notificationbox {
  background: #fff;
  border-radius: 15px;
  padding: 15px;
  margin: 10px 20px;
  text-align: left;
  position: absolute;
  top: 17rem;
}

.notime {
  float: right;
  text-align: right;
}

.notificationbox h2 {
  font-family: "helveticaneuemedium";
  padding-top: 10px;
}

/* Group Video Call   */
.outerdiv {
  position: relative;
}

.outerdiv img {
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 50vh;
}

.innertext {
  position: absolute;
  left: 10px;
  top: 10px;
  background: rgba(0, 0, 0, 0.5);
  padding: 3px 40px;
  border-radius: 50px;
  color: #fff;
}

.videoiconbottom {
  position: absolute;
  bottom: 20px;
  z-index: 9999;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%, 0%);
  display: block;
  width: 100%;
}

.videoimg {
  width: 100%;
  object-fit: cover;
  height: 500px;
}

.mtminu25 {
  margin-top: -65px;
}

.boxcallicon {
  width: 88px;
  height: 88px;
  border-radius: 50%;
}

.anchoroveray {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
}

.footerbottom .contentBody {
  padding-bottom: 95px;
}

/* OTP Screen */
.verification-code {
  /* max-width: 300px; */
  position: relative;
  margin: 20px auto 0px;
  /* text-align:center; */
}

.control-label {
  display: block;
  margin: 40px auto;
  font-weight: 900;
}

.verification-code--inputs input[type="text"] {
  border: 2px solid #e1e1e1;
  width: 46px;
  height: 46px;
  padding: 10px;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
}

/*   Popup Design   */
.popupbox {
  background: #fff;
  border-radius: 20px;
  text-align: center;
}

.popcontent {
  padding: 20px 20px;
}

.popcontent p {
  font-family: "HELVETICANEUERegular";
}

.popupbox h2 {
  text-align: center;
  padding-top: 0px;
}

.popupfooter {
  border-top: 1px solid #d6d6d6;
  position: relative;
  height: 50px;
}

.btnwidoutbg {
  background: #e7e7e7;
  border: 0px solid #fff;
  color: #000;
}

.btn1:after {
  content: "";
  display: block;
  width: 1px;
  background-color: #e1e1e1;
  height: 50px;
  position: absolute;
  top: 0;
  left: 50%;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0%);
}

.btnwidoutbg:hover,
.btnwidoutbg:focus {
  color: #000;
  background-color: transparent;
  outline: none !important;
  border-color: red;
  box-shadow: 0 0 0 0.25rem rgb(49 132 253 /0%);
}

.btn-check:active + .btnwidoutbg,
.btn-check:checked + .btnwidoutbg,
.btnwidoutbg.active,
.btnwidoutbg:active,
.show > .btnwidoutbg.dropdown-toggle {
  color: #000;
  background-color: transparent;
  border-color: transparent;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0%);
}

/* @media screen and (min-width: 768px) {
   .videoiconbottom{width:70%;}
  } */

@media screen and (max-width: 667px), screen and (max-height: 375px) {
}

.popcontent .uploadbox {
  background: #f6f4f9;
}

.positioncenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* min-height: 90vh; */
}

/*  ------------------- Primary Admin Css -------------------*/

.heading {
  font-size: 34px;
  padding-top: 20px;
}

.heading span {
  font-family: "helveticaneuebold";
}

.mt-n1 {
  margin-top: -1px;
}

/* admin-settings */
.topspace {
  padding: 1rem 1rem 3rem;
}

/*  Billing Details   */
.list ul li {
  font-size: 18px;
}

.list ul li span {
  float: right;
  text-align: right;
}

a.clrblue {
  text-decoration: underline;
  font-family: "helveticaneuebold";
}

.billdetails h5 {
  font-size: 20px;
  font-family: "helveticaneuemedium";
}

/* Add Covid Certificate */
.vaccineuploadbox {
  width: 100%;
}

/*   Manage Apps   */
.manageapps .media .align-self-center {
  width: 120px !important;
  height: 120px;
  min-width: 120px;
  border: 1px solid #fff;
  max-width: unset;
  object-fit: contain !important;
}

.manageapps .media {
  padding: 0.5rem 20px 0.5rem 0.5rem;
  border-radius: 49px;
}

.musicCircle {
  width: 130px;
  height: 130px;
}

.musicCircleInner {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: linear-gradient(45deg, #bc75ff, #8f01dc);
  padding: 5px;
}

.musicImgInner {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ddd;
}

.musicImgInner img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

/* .form-switch {
  display: inline-flex;
  position: absolute;
  right: 20px;
} */
.form-switch {
  padding-left: 0px;
}

.form-switch input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.form-switch input[type="checkbox"]:checked + label {
  background: #5e2b97;
  border: 2px solid #5e2b97;
}

.form-switch input[type="checkbox"]:checked + label::after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.form-switch label {
  cursor: pointer;
  width: 50px;
  height: 26px;
  background: #d6d6d6;
  border: 2px solid #d6d6d6;
  display: block;
  border-radius: 24px;
  position: relative;
}

.form-switch label::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 4px;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 16px;
  transition: 0.3s;
}

.manageapps .media-body {
  margin-right: 50px;
}

.manageapps .media-body h5 {
  line-height: 27px;
  color: #372251;
}

/* Successful */
.bluebox {
  background: #5e2b97;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 40px 0px;
}

.bluebox h5 {
  font-family: "helveticaneuebold";
}

.successful .card {
  border-radius: 10px;
  border: 0px solid #fff;
}

.circlemark {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.successful .card-body {
  padding: 40px 20px;
}

.successful .card-text {
  font-size: 22px;
}

/* Manage Reminders */
.boxwhite {
  background: #fff;
  border-radius: 20px;
  padding: 1.2rem 15px;
  align-items: center;
  position: relative;
  width: 100%;
  position: relative;
  display: flex;
  margin-bottom: 12px;
}

.days {
  display: flex;
  text-align: center;
  justify-content: center;
}

.days h5 {
  margin: 0px 5px 0px 5px;
  color: #828282;
  font-family: "helveticaneuebold";
}

.days .dayselect {
  color: #5e2b97;
}

.centerdays {
  margin: auto;
  padding-right: 50px;
  text-align: center;
}

.dayselectclr {
  color: #5e2b97 !important;
}

/* Add Reminder */
.timer h2 {
  text-align: center;
  font-size: 72px;
  color: rgba(94, 43, 151, 0.1);
  margin-bottom: 0px;
  font-family: "helveticaneuemedium";
}

.timeselected {
  color: rgba(94, 43, 151, 1) !important;
}

.circledays {
  display: flex;
}

.day1 {
  width: 35px;
  height: 35px;
  font-size: 1.6rem;
  border-radius: 50px;
  border: 1px solid #5e2b97;
  color: #5e2b97;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-right: 5px;
  font-size: 18px;
  cursor: pointer;
}

.dayselect {
  background: #5e2b97;
  color: #fff;
  font-family: "helveticaneuebold";
}

.timeselected span {
  border-top: 2px solid #5e2b97;
  border-bottom: 2px solid #5e2b97;
}

/* No Contacts Found */
.nocontact {
  background: #efeef1;
  border: 1px solid #dfdfdf;
  padding: 30px 20px;
  margin-top: 50px;
  border-radius: 10px;
  color: #e21b29;
}

/* Choose Primary Admin */
.primaryadmin .media {
  border: 1px solid #fff;
  color: #372251;
}

.primaryadmin .media .align-self-center {
  width: 70px;
  height: 70px;
  border: 1px solid #707070;
  display: block;
}

.primaryadmin .media:hover {
  border: 1px solid #5e2b97;
}

.primaryadmin .media:hover h5 {
  color: #5e2b97;
}

.imghoverbox {
  position: relative;
}

.imageover {
  display: block;
  width: 100%;
  height: auto;
}

.overlaypurple {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  width: 70px;
  opacity: 0;
  border-radius: 50%;
  transition: 0.5s ease;
  background-color: rgba(94, 43, 151, 74%);
}

.primaryadmin .media:hover .overlaypurple {
  opacity: 1;
}

.overlayicon {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

/* Loader */
#overlay {
  background: #000;
  color: #666666;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  opacity: 0.8;
}

.spinner {
  margin: 0 auto;
  height: 64px;
  width: 64px;
  animation: rotate 0.8s infinite linear;
  border: 6px solid #0792e2;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -32px 0 0 -32px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Create Group */
.selectcontactmedia .media {
  border: 1px solid #fff;
  color: #372251;
}

.selectcontactmedia .media .align-self-center {
  width: 55px;
  height: 55px;
  border: 1px solid #707070;
  display: block;
}

.selectcontactmedia .media:hover {
  border: 1px solid #5e2b97;
}

.selectcontactmedia .media:hover h5 {
  color: #5e2b97;
}

.selectcontactmedia .imghoverbox {
  position: relative;
}

.selectcontactmedia .imageover {
  display: block;
  width: 100%;
  height: auto;
}

.selectcontactmedia .overlaypurple {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 55px;
  width: 55px;
  opacity: 0;
  border-radius: 50%;
  transition: 0.5s ease;
  background-color: rgba(94, 43, 151, 74%);
}

.selectcontactmedia .media:hover .overlaypurple {
  opacity: 1;
}

.selectcontactmedia .overlayicon {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.removebox {
  width: 30px;
  height: 30px;
  background: #e53737;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addbox {
  background: #23b97f;
}

.btnaddremove {
  border-radius: 50px;
  padding: 5px 20px;
  font-size: 20px;
  font-family: "helveticaneuebold";
}

/*New Css*/
.imgViewBox {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 1px solid #5e2b97;
  object-fit: contain;
  margin-right: 10px;
  padding: 13px;
  text-align: center;
}

.imgViewBox img {
  width: 26px;
  height: 26px;
}

.certificateBlog {
  text-align: left !important;
  border-bottom: 1px solid #c7bfd1;
  padding: 10px 0;
  margin-bottom: 30px;
}

.align-self-center.DuIocns {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 1px solid #5e2b97;
  object-fit: contain;
  margin-right: 10px;
  padding: 13px;
}

.photosBlog {
  position: relative;
}

.imgSelectCheck {
  position: absolute;
  top: 10px;
  left: 10px;
}

.imgSelectCheck .form-group input:checked + label::after {
  top: 8px;
  left: 15px;
}

.imgSelectCheck .form-group label::before {
  padding: 15px;
  background-color: #fff;
}

.wishlistbox.purpleBg {
  background: #5e2b97;
}

.heightFull {
  height: auto !important;
}

.shareIconsBlog {
  display: flex;
  justify-content: center;
}

.shareIconsBlog .wishlistbox {
  margin: 15px;
}

.leftSlideArrow {
  position: absolute;
  top: 50%;
  left: 10px;
  width: 60px;
}

.rightSlideArrow {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 60px;
}

.checkBoxSelect h5 {
  padding-top: 11px;
}

.imghoverbox {
  position: relative;
}

.slectBlogNormal label {
  border: 1px solid #f7f7f7;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px !important;
}

.checkBoxSelect input[type="checkbox"]:checked + label {
  border: 1px solid #5e2b97;
  background: #fff;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
}

.checkBoxSelect input[type="checkbox"]:checked + label .imghoverbox::after {
  background: rgba(94, 43, 151, 74%) url(../images/selctContact.png) center
    center;
  content: "";
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50px;
  width: 55px;
  height: 55px;
}

.tnc-container {
  height: calc(100vh - 300px);
  overflow-y: auto;
}

.country-prefix {
  position: absolute;
  left: 35px;
  top: 7px;
  font-weight: 600;
}

.iconInputPhoneLeft {
  padding-left: 70px;
}

.imgBoxs {
  width: 100%;
}

.rdtPicker {
  width: 100% !important;
  text-align: center;
  position: relative;
}

.rdtCounters {
  font-size: 26px !important;
}

.rdtCounterSeparator {
  line-height: 189px;
}

.rdtCounter {
  height: auto !important;
  width: 85px !important;
}

.rdtCounter .rdtBtn {
  height: 100%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  color: #fff;
  border: none;
  background: #5e2b97;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 10px;
}

.rdtCounter .rdtBtn {
  height: 100%;
  line-height: 65px !important;
}

.rdtCounter .rdtCount {
  height: 60px;
  font-size: 2.4rem !important;
  font-weight: bold;
  background: #e6e0ed;
  line-height: 3.8rem;
  margin: 10px 0px;
  border-radius: 10px;
}

.rdtCounter .rdtBtn {
  height: 100% !important;
}

.rdtCounterSeparator {
  padding-top: 50px !important;
  font-size: 2.6rem;
}

.rdtCounterSeparator + .rdtCounter {
  margin-right: 5px;
}

.rdtOpen .rdtPicker {
  display: block;
  position: relative;
  border: none !important;
  box-shadow: none;
  margin-top: 15px;
  z-index: 8 !important;
}

.rdtPicker .rdtCounter .rdtBtn:hover {
  background: #5e2b97 !important;
}

body .rdtPicker .rdtCounter .rdtBtn:active {
  background: #eee !important;
  color: #5e2b97;
}

.modal-content {
  border-radius: 20px;
  max-width: 400px;
  width: 90%;
  margin-left: -25px;
}

.modal-dialog-centered {
  justify-content: center;
}

.modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 3.5rem);
  justify-content: center;
}

.modal-fullscreen .modal-content {
  width: 100% !important;
  border-radius: 30px 30px 0 0 !important;
  margin: auto !important;
}

.modal-backdrop.show {
  opacity: 0.3 !important;
}

.modal-backdrop.show + .modal + .fade.modal-backdrop.show {
  z-index: 1061;
}

.modal-backdrop.show + .modal + .fade.modal-backdrop.show + .modal {
  z-index: 1062;
}

.purBg {
  background: #5e2b97;
}

.spleshBlog {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.bottomLogoBg {
  background: #5e2b97;
  border-top: 1px solid #5e2b97;
}

.positionRel {
  position: relative !important;
}

.otpbox {
  padding-left: 15px;
}

.loginfix {
  padding: 50px 0px 25px 0px;
}

.bgnone {
  background: none;
}

.newTopBlog {
  color: #5e2b97;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;
}



/* .sectionHeader:first-letter {
  text-transform: uppercase;
} */

.subTitleBlog {
  font-size: 16px;
  color: #000;
  margin-bottom: 15px;
  padding-top: 5px;
}

.form-switch input[type="checkbox"]:checked + span + label {
  background: #5e2b97;
  border: 2px solid #5e2b97;
}

.form-switch input[type="checkbox"]:checked + span + label::after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

/* 
.form-switch span::after {
  position: absolute;
  top: -22px;
  right: 3px;
  font-size: 14px;
  width: 44px;
  text-align: center;
  content: "Off";
  font-weight: bold;
} */

.form-switch input[type="checkbox"]:checked + span::after {
  content: "On";
}

.react-autosuggest__suggestions-container ul {
  display: none;
  background: #fff;
  margin: 12px 0 0 0;
  border-radius: 5px;
  box-shadow: 0px 3px 14px 0px rgb(0 0 0 / 10%);
  padding: 10px;
  position: absolute;
  width: calc(100% - 65px);
  max-height: 180px;
  z-index: 99;
  overflow: auto;
}

.react-autosuggest__suggestions-container ul li {
  padding: 5px 10px;
}

.react-autosuggest__suggestions-container ul li:hover,
.react-autosuggest__suggestions-container ul li:focus {
  background: #f6f4f9;
}

.mLeft0 {
  margin-left: 0 !important;
}

.bgWhite {
  background: #fff !important;
}

.otpInput:first-child {
  padding-left: 0px;
}

.otpInput input {
  width: 3.5rem !important;
  height: 3.5rem;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.modal-footer {
  justify-content: center;
  position: relative;
}

/* .modal-footer::after {
  position: absolute;
  width: 1px;
  height: 100%;
  background: #dee2e6;
  content: "";
  left: 50%;
  margin-left: -1px;
} */
.modal-footer .btn {
  font-size: 18px;
  font-weight: 600;
  width: 47%;
}

.modal-header {
  text-align: center;
  border-bottom: none;
  padding-bottom: 0px;
  padding-top: 20px;
}

.modal-header h3 {
  font-weight: 600;
  color: #000;
}

.modal-header .btn-close {
  display: none;
}

.modal-body {
  font-size: 18px;
  color: #000;
  font-family: "HELVETICANEUERegular";
}

.deleteCer.removebox {
  padding: 2px;
  width: 40px;
  height: 40px;
  z-index: 1;
}

.uploadbox.vaccineuploadbox {
  height: 540px;
}

.uploadbox.vaccineuploadbox img {
  width: 40px;
}

.uploadbox p {
  font-size: 18px;
  color: #bfbfbf;
  font-family: "HELVETICANEUERegular";
  line-height: 20px;
}

.bgGrayHeight {
  min-height: calc(100vh - 165px) !important;
}

.menuHeaderText {
  color: #000;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 22px;
}

.menuScreenTitle {
  font-size: 1.25rem;
  font-weight: 500;
  color: #372251;
}

/****/
.modal-footer {
  padding: 10px;
  padding-bottom: 20px;
  margin-top: 0px;
  border: none;
}

.modal-footer .btn {
  font-size: 18px;
  font-weight: 600;
  width: 140px;
  border-radius: 50px;
  margin: 5px;
  padding: 8px 10px;
}

.modal-footer .btnwidoutbg {
  background: #fff;
  border: 0px solid #fff;
  color: #000;
}

.modal-body p {
  margin-bottom: 0px;
}

.uploadbox.vaccineuploadbox {
  height: calc(100vh - 300px);
}

.boxwhite.newUIReminer {
  display: block;
}

.boxwhite.newUIReminer .fsmed {
  display: inline-block;
}

.boxwhite.newUIReminer .form-switch {
  display: inline-block;
  position: absolute;
  right: 20px;
  text-align: center;
}

.boxwhite.newUIReminer .centerdays {
  margin: auto;
  padding-right: 0;
  text-align: left;
  margin-top: 5px;
}

.boxwhite.newUIReminer .days {
  justify-content: start;
}

.boxwhite.newUIReminer .days h5:first-child {
  margin-left: 0px;
}

.col-md-12 .boxmediablue.media {
  margin: 0px;
}

.newUIReminer .centerdays h5 {
  font-size: 1.1rem;
}

.boxwhite.newUIReminer {
  padding: 1rem 15px;
  margin-bottom: 12px;
}

.customer-info h1 {
  margin: 0;
}

.bottomLogo,
.bigpurplebottom {
  text-align: center;
  position: absolute;
  /* left: 0; */
  /* right: 0; */
  bottom: 0px;
  background: transparent !important;
  padding: 10px;
  box-shadow: none;
  width: 100%;
  max-width: 576px;
}

.btn-primary:hover {
  border-color: #421d6c;
}

.fs30 {
  font-size: 30px;
}

.bdayTitle {
  width: calc(100% - 70px);
}

.bdayTitle h5 {
  word-break: break-all;
}

@media screen and (max-width: 575px) {
  .callOuterContainer {
    padding: 0 12px;
  }

  .videocallcircle,
  .effect {
    height: 210px;
    width: 210px;
  }

  .fs30 {
    font-size: 26px;
  }

  .callImgHeight {
    height: 130px;
    max-width: 100%;
  }

  .callDeclineBtn {
    width: 180px;
  }

  .calltext {
    max-width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .callOuterContainer {
    padding: 0 12px;
  }

  .videocallcircle,
  .effect {
    height: 140px;
    width: 140px;
  }

  .fs30 {
    font-size: 20px;
  }

  .callImgHeight {
    height: 100px;
    max-width: 100%;
  }

  .callDeclineBtn {
    width: 140px;
  }

  .calltext {
    max-width: 100%;
  }
}

.rdt.rdtOpen {
  padding-top: 20px !important;
}

.rdt.rdtOpen .rdtPicker {
  background: none !important;
}

.passwordbtn {
  padding: 15px 20px;
}

.passwordbtn .iconEye {
  top: 13px !important;
  right: 15px !important;
}

.customer-info h3 b {
  font-family: "helveticaneuebold";
}

.userprofile.userProfileLogo {
  background: #fff;
  border: 1px solid #5e2b97;

  border-radius: 50%;
  height: 190px;
  width: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userprofile.userProfileLogo img {
  border: none;
  width: 140px;
}

/*New CSS CR */

.profilTileHeading {
  font-size: 20px !important;
  font-weight: 600;
  text-align: center;
}

.topTileHeading {
  font-size: 34px !important;
  font-weight: 600;
}

.topTileHeading span {
  font-weight: 400 !important;
  width: 100% !important;
  display: inline-block;
}

.numberCenter {
  text-align: center;
  font-size: 36px;
  font-weight: 400;
}

.whichRoleBox h4 {
  font-size: 20px;
  color: #945f5f;
  font-weight: bold;
}

.whichRoleBox h5 {
  font-size: 16px;
  color: #945f5f;
  font-weight: bold;
  margin-bottom: 0;
}

.whichRoleBox p {
  font-size: 14px;
  color: #945f5f;
}

/** 28-04-2022 **/
.mtminus {
  margin-top: -20px;
  position: relative;
  padding-bottom: 80px;
}

.urDone {
  font-size: 23px;
  color: #4d4e50;
}

.doItLater {
  color: #5e2b97;
  text-decoration: underline;
  font-size: 20px;
}

@media screen and (max-width: 470px) {
  .urDone {
    font-size: 19px;
    color: #4d4e50;
  }
}

/** 02-05-2022 **/
.mtminus {
  min-height: calc(100vh - 258px);
}

.leftArrowSelect .inputIcon select {
  padding-left: 40px;
}

.addNextRole label {
  width: 100%;
  display: inline-block;
}

.circlebox .form-group input[type="radio"] {
  display: block;
  position: absolute;
  opacity: 0;
}

/* 04-05-2022 */
.authorisedacOuter label {
  padding-left: 38px;
  text-indent: -38px;
}

.form-group label.radioLabel {
  margin-right: 15px;
}

.form-group label.radioLabel::before {
  border-radius: 30px;
}

/* menu screen */

.nextBtnBotmWelcome {
  min-height: calc(100vh - 410px);
  position: relative;
  padding-bottom: 65px;
}

.nextBtnBotm {
  min-height: calc(100vh - 360px);
  position: relative;
  padding-bottom: 65px;
}

.fullBtnBottomFix {
  width: 100%;
  position: absolute;
  bottom: 0px;
}

.contentbodymedia .topTileHeading span {
  font-family: "helveticaneuelight";
}

.newAnim span {
  width: 100%;
  height: 100%;
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  transform: scale(1.05);
  border: 2px solid #00838f;

  animation-name: callAnim;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.newAnim span:before {
  content: "";
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 1px solid #00838f;
  position: absolute;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transform: scale(1.1);
}

.newAnim span:after {
  content: "";
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 1px solid #00838f;
  position: absolute;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transform: scale(1.15);
}

.videocallcircle {
  animation-name: callAnim2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes callAnim {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.13);
  }

  100% {
    transform: scale(0.8);
  }
}

@keyframes callAnim2 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

/* add contact */
.label-bold {
  font-weight: bold;
}

.label-disable:before {
  border: 2px solid #b39bcf !important;
}

.raleway_font {
  font-family: "Raleway", sans-serif !important;
}

.manageapps .media-body .subTitleBlog {
  line-height: 1.4;
}

.userprofile1 img {
  object-fit: cover;
}

.relativeLoader {
  display: block;
  width: 100%;
  position: relative;
}

.loaderover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.loaderover:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f6f4f9;
  opacity: 0.8;
}

.flex_loaderbox {
  margin-top: 8px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
}

.flex_loaderbox span {
  padding-left: 15px;
  line-height: normal;
  font-family: "helveticaneuemedium";
}

.flex_loaderbox .spinner-border {
  min-width: 2rem;
}

.button-margin {
  margin-top: 50px;
}

.blogSupportChat span call-us {
  top: calc(20vh - 40%) !important;
  right: calc(90vw - 56%) !important;
}

.blogSupportChat {
  transform: scale(1.8);
  transform-origin: center center;
  -webkit-transform: scale(1.8);
  -webkit-transform-origin: center center;
}

.disable-role {
  opacity: 0.4;
}

.form-group .emgRadio input:checked + label:before {
  background: #ff4c4c !important;
  border-color: #ff4c4c !important;
}

/* .form-group .emgRadio label:before {
  border-color: #ff4c4c !important;
} */

.PhoneInput .PhoneInputInput {
  border: none !important;
  background-color: transparent !important;
}

.PhoneInputCountrySelect[disabled] {
  opacity: 0 !important;
  background-color: #e9ecef;
}

.PhoneInput--disabled {
  background-color: #e9ecef;
}

.help-support {
  position: fixed;
  bottom: 29px;
  text-align: center;
  left: 0;
  right: 0;
}

.help-support img {
  width: 100px;
}

.customtextbbx {
  display: block;
  padding-top: 20px;
}

.customtextbbx h2 {
  text-transform: uppercase;
  font-family: "helveticaneuebold";
}

.customtextbbx ol {
  text-align: left;
  color: #000;
  padding-left: 18px;
  font-family: "HELVETICANEUERegular";
}

.customtextbbx ol li {
  padding-left: 8px;
}

/* .customfilebx {
} */
.customfilebx input[type="file"],
.customfilebx input[type="text"],
.customprofilebox input[type="file"] {
  position: absolute;
  /* width: 100%;
  height: 100%; */
  /* opacity: 0;
  visibility: hidden; */
}

/* .customfilebx label,
.customprofilebox label {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  pointer-events: none;
} */

.file-input-hidden {
  opacity: 0;
  height: 100%;
  bottom: -6px;
  width: 40px;
}

.file-input-hidden-gallery {
  z-index: 1;
  top: 38px;
}

.customprofilegallerybox {
  position: relative;
  overflow: hidden;
}

.customprofilegallerybox input[type="file"] {
  position: absolute;
  border-radius: 30px;
  width: 100%;
  height: calc(100% - 20px);
}

.customprofilegallerybox .boxmediablue {
  pointer-events: none;
}

.certificate-container .preview_img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: calc(100vh - 300px);
}

.radioCircleImages .musicCircle {
  height: 100px;
  width: 100px;
  margin-right: 5px;
}
.radioCircleImages .music-img{
  margin-right: 10px;
}

.radioCircleImages .musicCircleInner {
  height:70px;
  width: 70px;
}

.radioCircleImages .musicImgInner {
  height:60px;
  width: 60px;
}
.radioCircleImages .musicImgInner img{
  width: 100%;
  height: 100%;
}


.manageapps .media.radioCircleImages img.align-self-center {
  height: 80px;
  min-width: 80px;
  width: 80px !important;
  margin: 0px !important;
  border: none !important;
}

.manageapps .radioCircleImages .media-body h5 {
  color: #372251;
  line-height: 22px;
  font-size: 1rem;
}



.checkBoxBlog {
  float: right;
  position: absolute;
  right: 10px;
  top: 25px;
}

.btnVidioCall {
  background: linear-gradient(to right, #35c998 0%, #23b97f 100%);
  border: 1px solid #fff;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  padding: 8px 20px;
  float: right;
  text-align: right;
}

.selectAllCheck {
  float: right;
  display: flex;
  justify-content: right;
  margin: 5px 0;
  font-size: 15px;
}

.selectAllCheck .form-check-label {
  padding-left: 5px;
}

.start-video-call {
  text-align: right;
  float: right;
  height: 50px;
}

.v-hidden {
  visibility: hidden;
}

/* group video call */
/*14-09-2022*/

.ringBg {
  background: #5e2b97;
  padding: 3px 5px !important;
  display: inherit;
  border-radius: 5px;
}

.ringBg a {
  font-size: 12px;
  color: #fff;
}

.messagesBox {
  background: #ead7ff;
  padding: 10px !important;
  font-size: 12px;
  color: #000;
  border-radius: 8px;
}

.bottomBlogBg {
  margin: 0;
  padding: 15px;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
}

.bottomBlogBg .accordion-button {
  background: none !important;
  border: none !important;
  padding: 0px !important;
  box-shadow: none !important;
}

.bottomBlogBg .accordion-item {
  border: none !important;
  background: none !important;
}

.bottomblogbg .accordion-button:focus {
  box-shadow: none !important;
}

.bottomblogbg .accordion-button::after {
  margin-left: 50% !important;
}

.contact-radio {
  width: 52px;
  font-size: 18px;
}

.contact-radio input {
  margin-right: 5px;
}

.annualReminder .form-switch {
  top: 10px;
}

.resendBtn {
  display: inline-block;
  font-size: 12px;
  color: #5e2b97;
  width: 100%;
  font-weight: 600;
}

.topTitleBg {
  background: #5f2b97;
  padding: 5px;
  width: 100%;
  margin-top: 8px;
  float: left;
}

.phoneSettingBox {
  background: #666666;
  padding: 5px;
  display: inline-block;
  width: 100%;
  overflow: auto;
  height: 250px;
}

.phoneSettingBox h2 {
  color: #fff;
}

.homeLeftIcon {
  float: left;
  width: 70px;
}

.homeRightIcon {
  float: right;
  width: 70px;
}

.parent {
  height: 13px;
}

.contentboxgray11 {
  bottom: 13px;
}

.titleHeadBox {
  height: 13px;
}

.titleHeadBox .contentboxgray11 {
  bottom: 13px;
}

.crsrPointer {
  cursor: pointer;
}

.noPointer {
  cursor: default !important;
}

.boxMediaGray {
  background-color: #edebf0;
}

.imagesLabelNo {
  right: 50px;
  background: #4085f9;
  padding: 5px 15px !important;
  color: #fff;
}

.imgNoCircleLable {
  position: absolute;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  background: #4085f9;
  color: #fff;
  font-size: 16px;
  right: 15px;
  text-align: center;
  line-height: 35px;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.2);
  top: 5px;
}

/* Input PIN Pad css */
.pin-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;
}

.inputclass {
  font-size: 24px;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.pin-pad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.buttonpin {
  font-size: 18px;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #d0d0d0;
}

button:active {
  background-color: #a0a0a0;
}

.media-list {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.media-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.media-list-icon-col {
  flex: 0 0 auto;
}

.media-list-content-col {
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 20px;
  flex-wrap: wrap;
}

.media-list li:last-child {
  margin-bottom: 0px;
}

.media-list li .form-switch {
  margin-left: auto;
}

/*--update css 12-04-24---*/
/* .light-btn {
  display: inline-block;
  background: #f6f4f9;
  padding: 8PX 15px;
  border-radius: 14PX;
  min-width: 150PX;
  text-align: center;
  font-size: 18px;
  color: #5e2b97;
  font-family: "helveticaneuebold";
} */

.light-btn {
  display: inline-block;
  background: #5e2b97;
  padding: 8px 15px;
  border-radius: 14px;
  min-width: 150px;
  text-align: center;
  font-size: 18px;
  color: #f6f4f9;
  font-family: "helveticaneuebold";
}

.light-btn:hover {
  color: #fff;
}

.no-bg {
  background: none;
}

/* .light-btn:hover, */
/* .light-btn:focus {
  color: #5e2b97;
} */

.media-box-white {
  padding: 20px;
  border-radius: 49px;
  margin: 0 0 20px;
  background: #fff;
}

.fw_medium {
  font-family: "helveticaneuemedium";
  font-weight: normal;
}

.webappimg-w {
  width: 180px;
}

.text-grey1 {
  color: #69676a;
}

.greentext {
  color: #01d767;
}

.fs-18 {
  font-size: 18px;
}

.fw-20 {
  font-size: 20px;
}

.media-img {
  width: 95px;
  height: 95px;
  border-radius: 50%;
  object-fit: contain;
}

.media-list-img {
  width: 120px;
  height: 120px;
  min-width: 120px;
  object-fit: contain !important;
}

.header-info h4 {
  font-size: 32px;
  font-family: "helveticaneuelight";
}

.header-info h4 span {
  font-size: 32px;
  font-family: "helveticaneuebold";
}

.header-webapp-img {
  max-width: 150px;
  height: auto;
  object-fit: contain;
}

.subTitleBlog.support-text {
  font-size: 14px;
}

.media-list-content-col .greentext {
  margin-right: auto;
}

/*--media query start here--*/

@media screen and (max-width: 460px) {
  .light-btn {
    padding: 8px;
    border-radius: 10px;
    min-width: 110px;
    font-size: 13px;
  }

  .light-btn1 {
    padding: 8px;
    border-radius: 10px;
    min-width: 110px;
    font-size: 13px;
  }

  .media-list li .form-switch {
    margin-top: 15px;
  }

  .media-list-img-col .media-list-img {
    width: 100px;
    height: 120px;
    min-width: 120px;
  }

  .subTitleBlog.support-text {
    font-size: 12px;
  }

  .media-list-content-col .greentext {
    font-size: 15px;
    width: 100%;
    margin-bottom: 10px;
  }

  .media-list-content-col .greentext + .form-switch {
    margin-top: 0;
  }
}

.gap-30 {
  gap: 30px;
}

strong {
  font-family: "helveticaneuebold";
}

/*--media query start here--*/

.pin-code-input {
  width: 100%;
  border-bottom: solid 1px #ccc;
  padding: 5px;
  border-width: 0 0 1px;
  text-align: center;
  color: #5e2b97;
  font-family: "helveticaneuebold";
}

.pinmodal {
  padding: 0 !important;
}

.pinmodal .modal-header {
  font-family: "helveticaneuemedium";
}

.rem-txt-link {
  color: red;
  background-color: transparent;
  /* Set background color to transparent */
  border: none;
  /* Remove border */
  padding: 0;
}

.icon-img {
  cursor: pointer;
}

.phone-set-row {
  display: flex;
  margin-bottom: 20px;
  background: #fff;
  padding: 20px;
  border-radius: 30px;
}

.border-red {
  border-color: red;
}

.phone-set-row h5 {
  color: #372251;
  font-size: 18px;
  font-family: helveticaneuemedium;
}

.phone-set-col-lft {
  flex: 1;
}
.phone-set-col-content {
  flex: 1;
}

.phone-set-img {
  flex: 0 0 60px;
  margin: 0 20px 0 0;
}

@media screen and (max-width: 480px) {
  .phone-set-col-lft {
    flex: 0 0 60%;
  }
  .voicemail_select {
    font-size: 14px;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  /* Optional: adds a semi-transparent background */
  z-index: 9999;
  /* Ensures it overlays other content */
}

.custom-loader {
  width: 80px;
}

.voice-mail-heading span {
  color: #5e2b97;
  font-family: "helveticaneuebold";
  position: relative;
}

.btn-primary-sm,
.btn-primary-sm:hover {
  border-radius: 8px;
  background: #5e2b97;
  border: 0;
  color: #fff;
  padding: 7px 15px;
  font-size: 14px;
  font-family: "helveticaneuebold";
}

.react-dropdown-select {
  border-radius: 8px !important;
  font-size: 14px;
  padding: 4px 10px;
}

.react-dropdown-select-dropdown-handle {
  margin: 0px;
}

.info-box {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 200px;
  top: 20px;
  /* Adjust this according to your design */
  left: 0px;
  /* Adjust this according to your design */
  z-index: 1000;
}

/* Style for the popup container */
.popup-content {
  display: none;
  position: absolute;
  top: 30px;
  right: -14px;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  width: 270px;
  line-height: normal;
  font-size: 13px;
  border: solid 1px #d8d8d8;
  border-radius: 8px;
  color: #000;
  font-family: "helveticaneuelight";
}
.popup-content::before {
  content: "";
  position: absolute;
  top: -7px; /* Position the caret above the popup */
  right: 15px; /* Adjust this to center the caret */
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff; /* Color of the caret */
  z-index: 1; /* Make sure the caret is above the border */
}

.popup-content::after {
  content: "";
  position: absolute;
  top: -8px; /* Position the caret's border above the caret */
  right: 14px; /* Adjust this to align with the caret */
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #d8d8d8; /* Border color of the caret */
  z-index: 0; /* Make sure the border is behind the caret */
}
/* Show the popup on hover */
.info-icon-wrapper:hover .popup-content,
.other-carriers-wrapper:hover .popup-content {
  display: block;
}

.maintance-wrapper {
  height: 100vh;
  background: #fff;
}
.maintance-container {
  padding: 30px;
  max-width: 570px;
  margin: auto;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.contentBody-white {
  background: #fff;
  padding: 0px 2rem 1rem;
}
.contentboxwhite {
  background: #fff;
  border-radius: 20px 20px 0px 0px;
  position: relative;
}
.maintenace-text-col {
  font-size: 12px;
  margin: 30px 0 80px;
  font-family: "helveticaneuemedium";
}

.opt-code-gp input:first-child{
  margin-left: 0px;
}
.opt-code-gp input{
  border: 1px solid #ccc;
  border-radius: 10px;
  height: 3.5rem;
  width: 3.5rem !important;
  margin: 0 10px;
}
.crsrPointer{
  cursor: pointer;
}

.otp-input {
  width: 50px; /* Adjust the width as needed */
  height: 50px; /* Adjust the height as needed */
  text-align: center; /* Center the text horizontally */
  font-size: 24px; /* Adjust the font size as needed */
  border: 1px solid #ccc; /* Add border style */
  border-radius: 4px; /* Optional: add rounded corners */
}

.otp-input:focus {
  outline: none;
  border-color: #007bff; /* Change border color on focus */
}
.form-check .form-check-input {
  margin-left: -1.8em;
}
.form-check {
  padding-left: 1.8em;
}

.form-check.form-switch{
padding-left: 0px;
}
.form-check-input{
  border: 2px solid #5e2b97;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .05), inset 0 -15px 10px -12px rgba(0, 0, 0, .05);
  padding: 10px;
}
.form-check-input:focus {
  border-color: #5e2b97;
  outline: 0;
  box-shadow: none;
}

.phone-set-col-content .form-control:focus {@font-face {
  font-family: "helveticaneuebold";
  src: url("../fonts/helveticaneuebold.woff2") format("woff2"),
    url("../fonts/helveticaneuebold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helveticaneuemedium";
  src: url("../fonts/helveticaneue_med.woff2") format("woff2"),
    url("../fonts/helveticaneue_med.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HELVETICANEUERegular";
  src: url("../fonts/HELVETICANEUERegular.woff2") format("woff2"),
    url("../fonts/HELVETICANEUERegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helveticaneuelight";
  src: url("../fonts/helveticaneue-light_0.woff2") format("woff2"),
    url("../fonts/helveticaneue-light_0.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helvetica_neueextended";
  src: url("../fonts/helveticaneue-extended.woff2") format("woff2"),
    url("../fonts/helveticaneue-extended.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.fsreg {
  font-family: "HELVETICANEUERegular";
}

.fsmed {
  font-family: "helveticaneuemedium";
}

.fslight {
  font-family: "helveticaneuelight";
}

.textstyle p {
  font-family: "helveticaneuelight";
  color: #000;
  line-height: 23px;
  font-size: 16px;
}

::placeholder {
  color: #bfbfbf !important;
}

*,
html {
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "helveticaneuelight";
  color: #333537;
  overflow-x: hidden;
  box-sizing: border-box;
  background: #f6f4f9;
  font-size: 18px;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  outline: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

::-moz-selection {
  color: #ffffff;
  background: #110358;
}

::selection {
  color: #ffffff;
  background: #110358;
}

:focus {
  outline: none;
}

.form-control:focus {
  border-color: transparent;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0);
}

.mainPage {
  max-width: 576px;
  margin: auto;
  position: relative;
  min-height: 100vh;
}

.btn-primary {
  color: #fff;
  background-color: #5e2b97;
  border-color: #5e2b97;
  border-radius: 50px;
  font-size: 20px;
  padding: 10px 14px;
  font-family: "helveticaneuebold";
}

.setpinbtn {
  color: #fff;
  background-color: #5e2b97;
  border-color: #5e2b97;
  border-radius: 30px;
  font-size: 20px;
  padding: 10px 30px;
  font-family: "helveticaneuebold";
}

.setpinbtn:hover,
.setpinbtn:focus {
  /* color: #fff; */
  background-color: #5e2b97;
}

.pt80 {
  padding-top: 80px;
}

.headerfixed {
  width: 100%;
  position: fixed;
  top: 0;
  /* left: 0; */
  margin: 0;
  z-index: 9;
  padding: 26px 20px;
  height: 80px;
  max-width: 576px;
}

.arrowfixed {
  width: 100%;
  position: fixed;
  top: 0;
  /* left: 0;  */
  margin: 0;
  z-index: 1;
  padding: 30px 0px;
  height: 80px;
  max-width: 576px;
  background: #f6f4f9;
}

.btn-primary:hover,
.btn-primary:focus {
  color: #fff;
  background-color: #421d6c;
  outline: none;
}

.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #c7bfd1;
  border-radius: 0;
  height: 42px;
  font-size: 18px;
  font-family: "HELVETICANEUERegular";
}

.form-label {
  font-size: 18px;
  color: #4d4e50;
  font-family: "HELVETICANEUERegular";
}

.form-check-input:checked {
  background-color: #5e2b97;
  border-color: #5e2b97;
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0);
}

.labelborder {
  border-bottom: 1px solid #c7bfd1;
}

.inputIcon {
  position: relative;
}

.iconInputLeft {
  padding-left: 40px;
}

.icons {
  position: absolute;
  left: 8px;
  top: 5px;
}

.aNormalT {
  color: #5e2b97;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
  float: right;
}

.bordernone {
  border: 0px solid transparent;
}

/*Splash Screen-------*/
/* .videoOuter {
    width: 100%;
    margin-top: 80px;
    max-height: 80vh;
} */
.username {
  font-size: 20px;
  font-family: "helveticaneuemedium";
}

.videoOuter {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.headerOuter {
  width: 100%;
  object-fit: cover;
}

.splashBg {
  background: #662ea4;
  background: -moz-linear-gradient(top, #662ea4 0%, #492574 100%);
  background: -webkit-linear-gradient(top, #662ea4 0%, #492574 100%);
  background: linear-gradient(to bottom, #662ea4 0%, #492574 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#662ea4', endColorstr='#492574', GradientType=0);
  width: 100%;
  height: 100vh;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.splashBg .logo {
  text-align: center;
}

.splashBg .logo a img {
  width: 18rem;
}

.bottomLogo {
  text-align: center;
  position: fixed;
  /* left: 0;
	right: 0; */
  bottom: 0px;
  background: #e4e4e4;
  padding: 10px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
  width: 100%;
  max-width: 576px;
}

.bigpurplebottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 15px;
  padding-top: 5px;
  /* background: #fff; */
  /*background: rgba(0,0,0,0.03);*/
  /* border-top: 1px solid #e9e9e9; */
}

.coptrighttext {
  font-size: 12px;
  line-height: 18px;
  padding-top: 10px;
  margin-bottom: 0px;
  font-family: "Raleway", sans-serif;
  color: #707070;
}

.coptrighttext b {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  color: #707070;
}

/*Login Screen-------*/
.loginPage {
  padding: 2rem 1rem 3rem;
}

.loginLogo {
  text-align: center;
  margin: auto;
}

.loginLogo a img {
  width: 10rem;
}

.loginPage h1 {
  font-size: 34px;
  color: #000;
  margin-bottom: 40px;
  font-family: "HELVETICANEUERegular";
}

.loginPage h1 span {
  width: 100%;
  float: left;
  line-height: 40px;
  font-family: "helveticaneuebold";
}

.orLine {
  background: #5e2b97;
  height: 1px;
  width: 100%;
  position: relative;
  margin: 30px 0 40px 0;
}

.orLine span {
  background: #5e2b97;
  text-align: center;
  border-radius: 50%;
  height: 36px;
  font-size: 13px;
  font-family: "helveticaneuebold";
  width: 36px;
  color: #fff;
  line-height: 36px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: -18px;
  transform: translateX(-50%);
}

.normalBtn {
  border: 1px solid #5e2b97;
  color: #5e2b97;
  border-radius: 50px;
  display: flex;
  font-size: 14px;
  padding: 10px 14px;
  font-family: "helveticaneuemedium";
  align-items: center;
  font-weight: 400;
  justify-content: center;
  margin-bottom: 15px;
}

.normalBtn i {
  padding-left: 5px;
  width: 1.5rem;
}

.iconEye {
  position: absolute;
  right: 10px;
  top: 5px;
}

.pt80 .mainPage {
  min-height: calc(100vh - 80px) !important;
}

/*Header Top----*/
.headerTop {
  min-height: 99px;
  padding: 5px 0;
}

.backIconTop {
  text-align: left;
}

.profilebox1 {
  display: flex;
  width: 100%;
  /* margin: auto; */
  position: relative;
  /* justify-content: center; */
  align-items: center;
}

.userprofile1 {
  position: relative;
  /* margin-top: -9rem; */
  text-align: center;
  margin-bottom: 30px;
}

.editprofile1 {
  width: 50px;
  height: 50px;
  background: #5e2b97;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  right: -10px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*  Home Page   */
.Homeheaderbg {
  background: #5e2b97;
  padding: 30px 20px 35px;
}

.Homeheaderbg1 {
  margin-bottom: 35px;
  background: #5e2b97;
  padding: 30px 20px 35px;
}

.ghBottom {
  padding: 30px 20px 17px;
}

.customer-info {
  display: flex;
  color: #fff;
  padding-top: 30px;
  justify-content: space-between;
}

.customerimg img {
  width: 126px;
  height: 126px;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
  border: 1px solid #5e2b97;
}

.imgspace {
  margin-right: auto;
  text-align: right;
  position: relative;

  display: flex;
  justify-content: end;
}

.imgspace .imgsmall {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  top: 40px;
  right: -10px;
}

.imgspace .imgEditSmall {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  top: 40px;
  right: -10px;
}

.imgspace .imgbig {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  position: relative;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered h4 {
  font-size: 14px;
  line-height: 18px;
  font-family: "Raleway", sans-serif;
  padding-top: 10px;
  margin-bottom: 0px;
}

.boxposition {
  position: relative;
  margin: 10px auto;
}

/* .imgspace:after {
    content: '';
    display: block;
    background: url(../images/settings.svg) !important;
    width: 45px;
    height: 45px;
    position: absolute;
    right: -5px;
    top:40px;
	background-repeat:no-repeat;
	z-index:999;
} */

.customer-info h1 {
  font-size: 32px;
  font-family: "helveticaneuebold";
}

.customer-info h3 {
  font-size: 28px;
  font-family: "helveticaneuelight";
}

.contentbox {
  background: #fff;
  border-radius: 20px;
  position: relative;
  margin-top: -20px;
}

.mt0 {
  margin-top: 0px;
}

.parent {
  position: relative;
  background: #5e2b97;
  height: 25px;
}

.parentheight {
  height: auto !important;
}

.whitebg {
  background-color: #fff !important;
}

.fulogin {
  padding-top: 25px;
}

.fulogin h1 {
  font-size: 26px;
  line-height: 40px;
}

.fs36 {
  font-size: 34px;
  font-family: "helveticaneuelight";
}

.fs36 b {
  font-family: "helveticaneuemedium";
  font-weight: normal;
}

.arrowleft {
  padding-bottom: 30px;
}

.fmsignup h2 {
  font-family: "helveticaneuebold";
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: 400 !important;
}

.fs12 {
  font-size: 14px;
  color: #945f5f;
  font-family: "HELVETICANEUERegular";
}

.fsreg b {
  font-family: "helveticaneuebold";
  font-weight: normal;
}

.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #5e2b97;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 5px;
}

.form-group input:checked + label:before {
  background: #5e2b97;
}

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.clrblue {
  color: #5e2b97;
}

.fsbold {
  font-family: "helveticaneuebold";
}

.fmsignup .iconInputLeft {
  color: #bfbfbf;
}

.contentbodypadd {
  /* padding: 0px 2rem; */
}

.fs24 {
  font-size: 24px !important;
}

.media {
  padding: 0.7rem 15px;
  align-items: center;
  position: relative;
  width: 100%;
  position: relative;
  display: flex;
}

.media img.align-self-center {
  min-width: 55px;
  height: 55px;
  max-width: 55px;
  border-radius: 50%;
  border: 1px solid #5e2b97;
  object-fit: cover;
  margin-right: 10px;
}

.boxmediablue img.align-self-center {
  width: 50px;
  max-width: 50px;
  height: 55px;
  border-radius: 0px;
  border: 0px solid #5e2b97;
  object-fit: contain;
  margin-right: 10px;
}

.boxmediablue h5 {
  font-family: "helveticaneuelight" !important;
}

.boxmediablue h5 b {
  font-family: "helveticaneuebold";
  font-weight: normal;
}

.mediahead {
  font-family: "helveticaneuelight";
}

.mediahead b {
  font-family: "helveticaneuemedium";
  font-weight: normal;
}

.media h5 {
  margin-bottom: 0px;
  font-family: "helveticaneuemedium";
}

/*.media h5{font-family: 'helveticaneuemedium';}  */
.fsrlight {
  font-family: "helveticaneuelight" !important;
}

.fsrlight b {
  font-family: "helveticaneuemedium";
  font-weight: normal;
}

.mediaarrow {
  position: absolute;
  right: 20px;
  padding-right: 0px !important;
}

.contentboxgray {
  /* background: #f6f4f9; */
  border-radius: 20px 20px 0px 0px;
  position: relative;
  /* margin-top: -50px; */
}

.contentboxgray h2 {
  font-size: 20px;
  font-family: "HELVETICANEUERegular";
  text-align: left;
  padding-top: 10px;
}

.contentboxgray h2 b {
  font-family: "helveticaneuebold";
  font-weight: normal;
}

.btngreen {
  background: #23b97f;
  background: -moz-linear-gradient(right, #35c998 0%, #23b97f 100%);
  background: -webkit-linear-gradient(right, #35c998 0%, #23b97f 100%);
  background: linear-gradient(to right, #35c998 0%, #23b97f 100%);
  border: 1px solid #fff;
  font-family: "HELVETICANEUERegular";
}

.btngreen img {
  width: 30px;
}

/* .btnblue {
  background: #23b97f;
  background: -moz-linear-gradient(right, #5e2b97 0%, #2f164c 100%);
  background: -webkit-linear-gradient(right, #5e2b97 0%, #2f164c 100%);
  background: linear-gradient(to right, #5e2b97 0%, #2f164c 100%);
  border: 1px solid #fff;
  font-family: "HELVETICANEUERegular";
} */

.btnblue {
  background: #5e2b97;
  font-family: "HELVETICANEUERegular";
}

.btnblue img {
  width: 30px;
}

.contentbodypadd h4 {
  font-weight: bold;
  padding: 30px 0px;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.circlebox .form-group label {
  margin-bottom: 10px;
}

.circlebox .form-group label:before {
  border-radius: 50px;
}

/*
.arrowfixed{    position: fixed!important;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 0;
    z-index: 99999;}
	/*
	.arrowfixed.darkHeader {
		background-color: #fff;
		border-bottom: 1px solid #ddd;
		height:60px;
	}
	*/

.fs20 {
  font-size: 20px;
  font-family: "Raleway", sans-serif !important;
  padding-right: 23px;
}

.contentboxgray11 {
  background: #f6f4f9;
  border-radius: 20px 20px 0px 0px;
  position: relative;
  /* bottom: 20px; */
  /* z-index: 10; */
}

.contentboxgraynotfixedheader {
  background: #f6f4f9;
  border-radius: 20px 20px 0px 0px;
  position: relative;
  bottom: 20px;
  /* z-index: 10; */
}

.boxmediawhite {
  background: #fff;
  border-radius: 20px;
  margin: 0px 0px 12px;
}

.boxmediablue {
  background: #5e2b97;
  background: -moz-linear-gradient(right, #bd8ef2 0%, #5e2b97 100%);
  background: -webkit-linear-gradient(right, #bd8ef2 0%, #5e2b97 100%);
  background: linear-gradient(to right, #bd8ef2 0%, #5e2b97 100%);
  border-radius: 20px;
  margin: 0px 20px 20px;
  justify-content: center;
}

.contentbodymedia {
  padding: 0px 1.2rem 1rem;
}

.uploadbox {
  width: 120px;
  height: 120px;
  border-radius: 15px;
  background: #fff;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
}

.uploadbox p {
  font-size: 16px;
  color: #bfbfbf;
  font-family: "helveticaneuelight";
  line-height: 19px;
}

.uploadbox img {
  width: 30px;
  padding-bottom: 10px;
}

.formupload {
  margin-top: 20px;
  padding-bottom: 15px;
}

.btnwhite {
  background: #fff;
  color: #5e2b97;
  border: 1px solid #fff;
}

.mtminus {
  margin-top: -20px;
}

.welcome b {
  font-family: "helveticaneuebold";
  font-weight: normal;
}

/* FU- Video call  */
.darkgraybg {
  background: #4d4e50;
  padding-bottom: 50px;
}

/* .videobox3{outline: 2px solid #00838F; outline-offset: 10px; border-radius:50%;}
.videobox2{outline: 1px solid #00838F; outline-offset: 20px; border-radius:50%;}
.videobox1{outline: 1px solid rgba(0,131,143,0.5); outline-offset: 30px; border-radius:50%; } */
.videocallcircle {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  /* border: 1px solid #5e2b97; */
  object-fit: cover;
  position: relative;
}

.effect {
  height: 240px;
  width: 240px;
  /* outline: 1px solid rgba(0, 131, 143, 0.5); */
  position: relative;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: auto;
  border-radius: 50%;
  /* outline-offset: 24px; */
  display: flex;
  justify-content: center;
}

/* size pseudo within from coordonates */
/* .effect:before {
  content: "";
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  outline: 1px solid #00838f;
  position: absolute;
  border-radius: 50%;
  outline-offset: 18px;
}
.effect:after {
  content: "";
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
  outline: 2px solid #00838f;
  position: absolute;
  border-radius: 50%;
  outline-offset: 12px;
} */
.fs38 {
  font-size: 38px;
}

.calltext {
  display: inline-block;
  width: 320px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-family: "helveticaneuelight";
}

.circlecall {
  width: 100px;
  height: 100px;
  background: #fff;
  margin: auto;
  border-radius: 50%;
  border: 4px solid #d5d5d5;
  position: relative;
}

.circlecall:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%)
    hue-rotate(312deg) brightness(97%) contrast(117%);
}

.circlecall:after {
  content: "";
  position: absolute;
  top: 50%;
  /* position the top  edge of the element at the middle of the parent */
  left: 50%;
  /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, -50%);
  background: #d00e0e;
  background: -moz-linear-gradient(top, #ff4a4a 0%, #d00e0e 100%);
  background: -webkit-linear-gradient(top, #ff4a4a 0%, #d00e0e 100%);
  background: linear-gradient(to bottom, #ff4a4a 0%, #d00e0e 100%);
  z-index: -1;
  width: 145px;
  height: 145px;
  border-radius: 50%;
  border: 5px solid #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
}

.circlecall:hover {
  background: #d00e0e;
  background: -moz-linear-gradient(top, #d00e0e 0%, #fe4949 100%);
  background: -webkit-linear-gradient(top, #d00e0e 0%, #fe4949 100%);
  background: linear-gradient(to bottom, #d00e0e 0%, #fe4949 100%);
  border: 4px solid #fd4848;
}

.circlecall h4 {
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
}

.circlecall:hover h4 {
  color: #fff;
}

.circlecall {
  width: 100px;
  height: 100px;
  background: #fff;
  margin: auto;
  border-radius: 50%;
  border: 4px solid #d5d5d5;
  position: relative;
}

.calltime {
  font-size: 18px;
  font-family: "HELVETICANEUERegular" !important;
}

/* FU- Video call 2  */

.videouser {
  position: absolute;
  right: 10px;
  top: 10px;
}

.videouser img {
  width: 100px;
}

.bigimg {
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 534px;
}

.smallimg {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100px;
  object-fit: cover;
}

/* Search Bar  */
.searchbar input {
  border-bottom: 1px solid #d5d5d5;
  position: absolute;
}

input#searchicon {
  background-image: url("../images/search.svg");
  background-size: 20px 40px;
  background-repeat: no-repeat;
  text-indent: 15px;
  transition: 0.3s;
  position: relative;
  top: 10px;
}

input#searchicons:focus {
  background-image: none;
  text-indent: 0px;
}

/*  Fm Manage Photos   */
.imageupload img {
  margin-bottom: 20px;
  /* width: 100%; */
  height: 136px;
  object-fit: cover;
  cursor: pointer;
}

.nocontact img {
  width: 31%;
}

@media screen and (min-width: 480px) {
  .nocontact img {
    width: 21% !important;
  }
}

.fullimg {
  position: relative;
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullimg img {
  object-fit: cover;
  max-height: 350px;
  max-width: 100%;
  width: auto;
}

.fix-img-container {
  height: 350px;
  width: 100%;
}

/*  FM Add Image  */
textarea#msz {
  background: transparent;
  border: none;
  border-bottom: 1px solid #c7bfd1;
  border-radius: 0;
  width: 100%;
  font-size: 18px;
}

.inputIcon h5 {
  font-family: "helveticaneuemedium";
  padding-top: 30px;
  font-size: 22px;
}

.textred {
  color: #e21b29;
}

.textred:hover {
  color: #fff;
  background: #e21b29;
  border: 1px solid #e21b29;
}

/* View Image   */
.bigimgview {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.smallimgview {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 60px;
  cursor: pointer;
}

.wishlistbox {
  width: 85px;
  height: 85px;
  background: #fff;
  border-radius: 50%;
  margin: 40px auto 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/*  FU Settings  */
.settingbox {
  background: #fff;
  border-radius: 15px;
  padding: 10px 20px;
}

.settingbox ul li {
  font-size: 22px;
  padding: 10px 0px;
  border-bottom: 1px solid #f3f1f6;
  display: flex;
  align-items: center;
  font-family: "helveticaneuelight";
}

.settingbox ul li a {
  display: flex;
  align-items: center;
  font-family: "helveticaneuelight";
}

.settingbox ul li:last-child {
  border-bottom: none;
}

.settingbox ul li img {
  width: 45px;
  height: 45px;
  padding-right: 15px;
}

/*   My Profile   */
.userprofile {
  position: relative;
  margin-top: -9rem;
  text-align: center;
  margin-bottom: 20px;
}

.passwordbtn {
  background: #fff;
  border-radius: 10px;
  border: 1px solid #fff;
  margin-top: 30px;
  color: #000;
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
}

.passwordbtn:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%)
    hue-rotate(312deg) brightness(97%) contrast(117%);
}

.profilebox {
  display: flex;
  width: 100%;
  margin: auto;
  position: relative;
  justify-content: center;
  align-items: center;
}

/* Edit Profile */
.editprofile {
  width: 50px;
  height: 50px;
  background: #5e2b97;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  right: 0;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myprofile {
  width: 50px;
  height: 50px;
  background: #47db8f;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  right: 35%;
  bottom: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myprofileadmin {
  background: #f54c82;
}

.myprofile img {
  width: 32px;
}

.profileimg {
  width: 190px;
  height: 190px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  margin: auto;
  border: 1px solid #5e2b97;
}

/* 
.myprofile:after{ content: '';
    display: block;
    background: url(../images/selectFamilyAdminList.svg) #47DB8F no-repeat !important;
    width: 50px;
    height: 50px;
	border-radius:50%;
	border:2px solid #fff;
    position: absolute;
    right: 125px;
    bottom: -20px;
	background-repeat:no-repeat;
background-position:center;} */

/* Change Password  */

.fsregular {
  font-family: "helveticaneuelight";
}

h3.fsregular {
  font-size: 30px;
}

.support h4 {
  font-size: 22px;
  font-family: "HELVETICANEUERegular";
}

.support span img {
  width: 25px;
  height: 30px;
}

.videocallicon {
  width: 170px;
  margin: 60px auto 10px;
}

.fs32 {
  font-size: 32px !important;
}

/*   System Notification  */
.notificationbg {
  background-image: url(../images/notificationbg.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.notificationbox {
  background: #fff;
  border-radius: 15px;
  padding: 15px;
  margin: 10px 20px;
  text-align: left;
  position: absolute;
  top: 17rem;
}

.notime {
  float: right;
  text-align: right;
}

.notificationbox h2 {
  font-family: "helveticaneuemedium";
  padding-top: 10px;
}

/* Group Video Call   */
.outerdiv {
  position: relative;
}

.outerdiv img {
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 50vh;
}

.innertext {
  position: absolute;
  left: 10px;
  top: 10px;
  background: rgba(0, 0, 0, 0.5);
  padding: 3px 40px;
  border-radius: 50px;
  color: #fff;
}

.videoiconbottom {
  position: absolute;
  bottom: 20px;
  z-index: 9999;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%, 0%);
  display: block;
  width: 100%;
}

.videoimg {
  width: 100%;
  object-fit: cover;
  height: 500px;
}

.mtminu25 {
  margin-top: -65px;
}

.boxcallicon {
  width: 88px;
  height: 88px;
  border-radius: 50%;
}

.anchoroveray {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
}

.footerbottom .contentBody {
  padding-bottom: 95px;
}

/* OTP Screen */
.verification-code {
  /* max-width: 300px; */
  position: relative;
  margin: 20px auto 0px;
  /* text-align:center; */
}

.control-label {
  display: block;
  margin: 40px auto;
  font-weight: 900;
}

.verification-code--inputs input[type="text"] {
  border: 2px solid #e1e1e1;
  width: 46px;
  height: 46px;
  padding: 10px;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
}

/*   Popup Design   */
.popupbox {
  background: #fff;
  border-radius: 20px;
  text-align: center;
}

.popcontent {
  padding: 20px 20px;
}

.popcontent p {
  font-family: "HELVETICANEUERegular";
}

.popupbox h2 {
  text-align: center;
  padding-top: 0px;
}

.popupfooter {
  border-top: 1px solid #d6d6d6;
  position: relative;
  height: 50px;
}

.btnwidoutbg {
  background: #e7e7e7;
  border: 0px solid #fff;
  color: #000;
}

.btn1:after {
  content: "";
  display: block;
  width: 1px;
  background-color: #e1e1e1;
  height: 50px;
  position: absolute;
  top: 0;
  left: 50%;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0%);
}

.btnwidoutbg:hover,
.btnwidoutbg:focus {
  color: #000;
  background-color: transparent;
  outline: none !important;
  border-color: red;
  box-shadow: 0 0 0 0.25rem rgb(49 132 253 /0%);
}

.btn-check:active + .btnwidoutbg,
.btn-check:checked + .btnwidoutbg,
.btnwidoutbg.active,
.btnwidoutbg:active,
.show > .btnwidoutbg.dropdown-toggle {
  color: #000;
  background-color: transparent;
  border-color: transparent;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0%);
}

/* @media screen and (min-width: 768px) {
   .videoiconbottom{width:70%;}
  } */

@media screen and (max-width: 667px), screen and (max-height: 375px) {
}

.popcontent .uploadbox {
  background: #f6f4f9;
}

.positioncenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* min-height: 90vh; */
}

/*  ------------------- Primary Admin Css -------------------*/

.heading {
  font-size: 34px;
  padding-top: 20px;
}

.heading span {
  font-family: "helveticaneuebold";
}

.mt-n1 {
  margin-top: -1px;
}

/* admin-settings */
.topspace {
  padding: 1rem 1rem 3rem;
}

/*  Billing Details   */
.list ul li {
  font-size: 18px;
}

.list ul li span {
  float: right;
  text-align: right;
}

a.clrblue {
  text-decoration: underline;
  font-family: "helveticaneuebold";
}

.billdetails h5 {
  font-size: 20px;
  font-family: "helveticaneuemedium";
}

/* Add Covid Certificate */
.vaccineuploadbox {
  width: 100%;
}

/*   Manage Apps   */
.manageapps .media .align-self-center {
  width: 120px !important;
  height: 120px;
  min-width: 120px;
  border: 1px solid #fff;
  max-width: unset;
  object-fit: contain !important;
}

.manageapps .media {
  padding: 0.5rem 20px 0.5rem 0.5rem;
  border-radius: 49px;
}

.musicCircle {
  width: 130px;
  height: 130px;
}

.musicCircleInner {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: linear-gradient(45deg, #bc75ff, #8f01dc);
  padding: 5px;
}

.musicImgInner {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ddd;
}

.musicImgInner img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

/* .form-switch {
  display: inline-flex;
  position: absolute;
  right: 20px;
} */
.form-switch {
  padding-left: 0px;
}

.form-switch input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.form-switch input[type="checkbox"]:checked + label {
  background: #5e2b97;
  border: 2px solid #5e2b97;
}

.form-switch input[type="checkbox"]:checked + label::after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.form-switch label {
  cursor: pointer;
  width: 50px;
  height: 26px;
  background: #d6d6d6;
  border: 2px solid #d6d6d6;
  display: block;
  border-radius: 24px;
  position: relative;
}

.form-switch label::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 4px;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 16px;
  transition: 0.3s;
}

.manageapps .media-body {
  margin-right: 50px;
}

.manageapps .media-body h5 {
  line-height: 27px;
  color: #372251;
}

/* Successful */
.bluebox {
  background: #5e2b97;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 40px 0px;
}

.bluebox h5 {
  font-family: "helveticaneuebold";
}

.successful .card {
  border-radius: 10px;
  border: 0px solid #fff;
}

.circlemark {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.successful .card-body {
  padding: 40px 20px;
}

.successful .card-text {
  font-size: 22px;
}

/* Manage Reminders */
.boxwhite {
  background: #fff;
  border-radius: 20px;
  padding: 1.2rem 15px;
  align-items: center;
  position: relative;
  width: 100%;
  position: relative;
  display: flex;
  margin-bottom: 12px;
}

.days {
  display: flex;
  text-align: center;
  justify-content: center;
}

.days h5 {
  margin: 0px 5px 0px 5px;
  color: #828282;
  font-family: "helveticaneuebold";
}

.days .dayselect {
  color: #5e2b97;
}

.centerdays {
  margin: auto;
  padding-right: 50px;
  text-align: center;
}

.dayselectclr {
  color: #5e2b97 !important;
}

/* Add Reminder */
.timer h2 {
  text-align: center;
  font-size: 72px;
  color: rgba(94, 43, 151, 0.1);
  margin-bottom: 0px;
  font-family: "helveticaneuemedium";
}

.timeselected {
  color: rgba(94, 43, 151, 1) !important;
}

.circledays {
  display: flex;
}

.day1 {
  width: 35px;
  height: 35px;
  font-size: 1.6rem;
  border-radius: 50px;
  border: 1px solid #5e2b97;
  color: #5e2b97;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-right: 5px;
  font-size: 18px;
  cursor: pointer;
}

.dayselect {
  background: #5e2b97;
  color: #fff;
  font-family: "helveticaneuebold";
}

.timeselected span {
  border-top: 2px solid #5e2b97;
  border-bottom: 2px solid #5e2b97;
}

/* No Contacts Found */
.nocontact {
  background: #efeef1;
  border: 1px solid #dfdfdf;
  padding: 30px 20px;
  margin-top: 50px;
  border-radius: 10px;
  color: #e21b29;
}

/* Choose Primary Admin */
.primaryadmin .media {
  border: 1px solid #fff;
  color: #372251;
}

.primaryadmin .media .align-self-center {
  width: 70px;
  height: 70px;
  border: 1px solid #707070;
  display: block;
}

.primaryadmin .media:hover {
  border: 1px solid #5e2b97;
}

.primaryadmin .media:hover h5 {
  color: #5e2b97;
}

.imghoverbox {
  position: relative;
}

.imageover {
  display: block;
  width: 100%;
  height: auto;
}

.overlaypurple {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  width: 70px;
  opacity: 0;
  border-radius: 50%;
  transition: 0.5s ease;
  background-color: rgba(94, 43, 151, 74%);
}

.primaryadmin .media:hover .overlaypurple {
  opacity: 1;
}

.overlayicon {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

/* Loader */
#overlay {
  background: #000;
  color: #666666;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  opacity: 0.8;
}

.spinner {
  margin: 0 auto;
  height: 64px;
  width: 64px;
  animation: rotate 0.8s infinite linear;
  border: 6px solid #0792e2;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -32px 0 0 -32px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Create Group */
.selectcontactmedia .media {
  border: 1px solid #fff;
  color: #372251;
}

.selectcontactmedia .media .align-self-center {
  width: 55px;
  height: 55px;
  border: 1px solid #707070;
  display: block;
}

.selectcontactmedia .media:hover {
  border: 1px solid #5e2b97;
}

.selectcontactmedia .media:hover h5 {
  color: #5e2b97;
}

.selectcontactmedia .imghoverbox {
  position: relative;
}

.selectcontactmedia .imageover {
  display: block;
  width: 100%;
  height: auto;
}

.selectcontactmedia .overlaypurple {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 55px;
  width: 55px;
  opacity: 0;
  border-radius: 50%;
  transition: 0.5s ease;
  background-color: rgba(94, 43, 151, 74%);
}

.selectcontactmedia .media:hover .overlaypurple {
  opacity: 1;
}

.selectcontactmedia .overlayicon {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.removebox {
  width: 30px;
  height: 30px;
  background: #e53737;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addbox {
  background: #23b97f;
}

.btnaddremove {
  border-radius: 50px;
  padding: 5px 20px;
  font-size: 20px;
  font-family: "helveticaneuebold";
}

/*New Css*/
.imgViewBox {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 1px solid #5e2b97;
  object-fit: contain;
  margin-right: 10px;
  padding: 13px;
  text-align: center;
}

.imgViewBox img {
  width: 26px;
  height: 26px;
}

.certificateBlog {
  text-align: left !important;
  border-bottom: 1px solid #c7bfd1;
  padding: 10px 0;
  margin-bottom: 30px;
}

.align-self-center.DuIocns {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 1px solid #5e2b97;
  object-fit: contain;
  margin-right: 10px;
  padding: 13px;
}

.photosBlog {
  position: relative;
}

.imgSelectCheck {
  position: absolute;
  top: 10px;
  left: 10px;
}

.imgSelectCheck .form-group input:checked + label::after {
  top: 8px;
  left: 15px;
}

.imgSelectCheck .form-group label::before {
  padding: 15px;
  background-color: #fff;
}

.wishlistbox.purpleBg {
  background: #5e2b97;
}

.heightFull {
  height: auto !important;
}

.shareIconsBlog {
  display: flex;
  justify-content: center;
}

.shareIconsBlog .wishlistbox {
  margin: 15px;
}

.leftSlideArrow {
  position: absolute;
  top: 50%;
  left: 10px;
  width: 60px;
}

.rightSlideArrow {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 60px;
}

.checkBoxSelect h5 {
  padding-top: 11px;
}

.imghoverbox {
  position: relative;
}

.slectBlogNormal label {
  border: 1px solid #f7f7f7;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px !important;
}

.checkBoxSelect input[type="checkbox"]:checked + label {
  border: 1px solid #5e2b97;
  background: #fff;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
}

.checkBoxSelect input[type="checkbox"]:checked + label .imghoverbox::after {
  background: rgba(94, 43, 151, 74%) url(../images/selctContact.png) center
    center;
  content: "";
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50px;
  width: 55px;
  height: 55px;
}

.tnc-container {
  height: calc(100vh - 300px);
  overflow-y: auto;
}

.country-prefix {
  position: absolute;
  left: 35px;
  top: 7px;
  font-weight: 600;
}

.iconInputPhoneLeft {
  padding-left: 70px;
}

.imgBoxs {
  width: 100%;
}

.rdtPicker {
  width: 100% !important;
  text-align: center;
  position: relative;
}

.rdtCounters {
  font-size: 26px !important;
}

.rdtCounterSeparator {
  line-height: 189px;
}

.rdtCounter {
  height: auto !important;
  width: 85px !important;
}

.rdtCounter .rdtBtn {
  height: 100%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  color: #fff;
  border: none;
  background: #5e2b97;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 10px;
}

.rdtCounter .rdtBtn {
  height: 100%;
  line-height: 65px !important;
}

.rdtCounter .rdtCount {
  height: 60px;
  font-size: 2.4rem !important;
  font-weight: bold;
  background: #e6e0ed;
  line-height: 3.8rem;
  margin: 10px 0px;
  border-radius: 10px;
}

.rdtCounter .rdtBtn {
  height: 100% !important;
}

.rdtCounterSeparator {
  padding-top: 50px !important;
  font-size: 2.6rem;
}

.rdtCounterSeparator + .rdtCounter {
  margin-right: 5px;
}

.rdtOpen .rdtPicker {
  display: block;
  position: relative;
  border: none !important;
  box-shadow: none;
  margin-top: 15px;
  z-index: 8 !important;
}

.rdtPicker .rdtCounter .rdtBtn:hover {
  background: #5e2b97 !important;
}

body .rdtPicker .rdtCounter .rdtBtn:active {
  background: #eee !important;
  color: #5e2b97;
}

.modal-content {
  border-radius: 20px;
  max-width: 400px;
  width: 90%;
  margin-left: -25px;
}

.modal-dialog-centered {
  justify-content: center;
}

.modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 3.5rem);
  justify-content: center;
}

.modal-fullscreen .modal-content {
  width: 100% !important;
  border-radius: 30px 30px 0 0 !important;
  margin: auto !important;
}

.modal-backdrop.show {
  opacity: 0.3 !important;
}

.modal-backdrop.show + .modal + .fade.modal-backdrop.show {
  z-index: 1061;
}

.modal-backdrop.show + .modal + .fade.modal-backdrop.show + .modal {
  z-index: 1062;
}

.purBg {
  background: #5e2b97;
}

.spleshBlog {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.bottomLogoBg {
  background: #5e2b97;
  border-top: 1px solid #5e2b97;
}

.positionRel {
  position: relative !important;
}

.otpbox {
  padding-left: 15px;
}

.loginfix {
  padding: 50px 0px 25px 0px;
}

.bgnone {
  background: none;
}

.newTopBlog {
  color: #5e2b97;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;
}





/* .sectionHeader:first-letter {
  text-transform: uppercase;
} */

.subTitleBlog {
  font-size: 16px;
  color: #000;
  margin-bottom: 15px;
  padding-top: 5px;
}

.form-switch input[type="checkbox"]:checked + span + label {
  background: #5e2b97;
  border: 2px solid #5e2b97;
}

.form-switch input[type="checkbox"]:checked + span + label::after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

/* 
.form-switch span::after {
  position: absolute;
  top: -22px;
  right: 3px;
  font-size: 14px;
  width: 44px;
  text-align: center;
  content: "Off";
  font-weight: bold;
} */

.form-switch input[type="checkbox"]:checked + span::after {
  content: "On";
}

.react-autosuggest__suggestions-container ul {
  display: none;
  background: #fff;
  margin: 12px 0 0 0;
  border-radius: 5px;
  box-shadow: 0px 3px 14px 0px rgb(0 0 0 / 10%);
  padding: 10px;
  position: absolute;
  width: calc(100% - 65px);
  max-height: 180px;
  z-index: 99;
  overflow: auto;
}

.react-autosuggest__suggestions-container ul li {
  padding: 5px 10px;
}

.react-autosuggest__suggestions-container ul li:hover,
.react-autosuggest__suggestions-container ul li:focus {
  background: #f6f4f9;
}

.mLeft0 {
  margin-left: 0 !important;
}

.bgWhite {
  background: #fff !important;
}

.otpInput:first-child {
  padding-left: 0px;
}

.otpInput input {
  width: 3.5rem !important;
  height: 3.5rem;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.modal-footer {
  justify-content: center;
  position: relative;
}

/* .modal-footer::after {
  position: absolute;
  width: 1px;
  height: 100%;
  background: #dee2e6;
  content: "";
  left: 50%;
  margin-left: -1px;
} */
.modal-footer .btn {
  font-size: 18px;
  font-weight: 600;
  width: 47%;
}

.modal-header {
  text-align: center;
  border-bottom: none;
  padding-bottom: 0px;
  padding-top: 20px;
}

.modal-header h3 {
  font-weight: 600;
  color: #000;
}

.modal-header .btn-close {
  display: none;
}

.modal-body {
  font-size: 18px;
  color: #000;
  font-family: "HELVETICANEUERegular";
}

.deleteCer.removebox {
  padding: 2px;
  width: 40px;
  height: 40px;
  z-index: 1;
}

.uploadbox.vaccineuploadbox {
  height: 540px;
}

.uploadbox.vaccineuploadbox img {
  width: 40px;
}

.uploadbox p {
  font-size: 18px;
  color: #bfbfbf;
  font-family: "HELVETICANEUERegular";
  line-height: 20px;
}

.bgGrayHeight {
  min-height: calc(100vh - 165px) !important;
}

.menuHeaderText {
  color: #000;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 22px;
}

.menuScreenTitle {
  font-size: 1.25rem;
  font-weight: 500;
  color: #372251;
}

/****/
.modal-footer {
  padding: 10px;
  padding-bottom: 20px;
  margin-top: 0px;
  border: none;
}

.modal-footer .btn {
  font-size: 18px;
  font-weight: 600;
  width: 140px;
  border-radius: 50px;
  margin: 5px;
  padding: 8px 10px;
}

.modal-footer .btnwidoutbg {
  background: #fff;
  border: 0px solid #fff;
  color: #000;
}

.modal-body p {
  margin-bottom: 0px;
}

.uploadbox.vaccineuploadbox {
  height: calc(100vh - 300px);
}

.boxwhite.newUIReminer {
  display: block;
}

.boxwhite.newUIReminer .fsmed {
  display: inline-block;
}

.boxwhite.newUIReminer .form-switch {
  display: inline-block;
  position: absolute;
  right: 20px;
  text-align: center;
}

.boxwhite.newUIReminer .centerdays {
  margin: auto;
  padding-right: 0;
  text-align: left;
  margin-top: 5px;
}

.boxwhite.newUIReminer .days {
  justify-content: start;
}

.boxwhite.newUIReminer .days h5:first-child {
  margin-left: 0px;
}

.col-md-12 .boxmediablue.media {
  margin: 0px;
}

.newUIReminer .centerdays h5 {
  font-size: 1.1rem;
}

.boxwhite.newUIReminer {
  padding: 1rem 15px;
  margin-bottom: 12px;
}

.customer-info h1 {
  margin: 0;
}

.bottomLogo,
.bigpurplebottom {
  text-align: center;
  position: absolute;
  /* left: 0; */
  /* right: 0; */
  bottom: 0px;
  background: transparent !important;
  padding: 10px;
  box-shadow: none;
  width: 100%;
  max-width: 576px;
}

.btn-primary:hover {
  border-color: #421d6c;
}

.fs30 {
  font-size: 30px;
}

.bdayTitle {
  width: calc(100% - 70px);
}

.bdayTitle h5 {
  word-break: break-all;
}

@media screen and (max-width: 575px) {
  .callOuterContainer {
    padding: 0 12px;
  }

  .videocallcircle,
  .effect {
    height: 210px;
    width: 210px;
  }

  .fs30 {
    font-size: 26px;
  }

  .callImgHeight {
    height: 130px;
    max-width: 100%;
  }

  .callDeclineBtn {
    width: 180px;
  }

  .calltext {
    max-width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .callOuterContainer {
    padding: 0 12px;
  }

  .videocallcircle,
  .effect {
    height: 140px;
    width: 140px;
  }

  .fs30 {
    font-size: 20px;
  }

  .callImgHeight {
    height: 100px;
    max-width: 100%;
  }

  .callDeclineBtn {
    width: 140px;
  }

  .calltext {
    max-width: 100%;
  }
}

.rdt.rdtOpen {
  padding-top: 20px !important;
}

.rdt.rdtOpen .rdtPicker {
  background: none !important;
}

.passwordbtn {
  padding: 15px 20px;
}

.passwordbtn .iconEye {
  top: 13px !important;
  right: 15px !important;
}

.customer-info h3 b {
  font-family: "helveticaneuebold";
}

.userprofile.userProfileLogo {
  background: #fff;
  border: 1px solid #5e2b97;

  border-radius: 50%;
  height: 190px;
  width: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userprofile.userProfileLogo img {
  border: none;
  width: 140px;
}

/*New CSS CR */

.profilTileHeading {
  font-size: 20px !important;
  font-weight: 600;
  text-align: center;
}

.topTileHeading {
  font-size: 34px !important;
  font-weight: 600;
}

.topTileHeading span {
  font-weight: 400 !important;
  width: 100% !important;
  display: inline-block;
}

.numberCenter {
  text-align: center;
  font-size: 36px;
  font-weight: 400;
}

.whichRoleBox h4 {
  font-size: 20px;
  color: #945f5f;
  font-weight: bold;
}

.whichRoleBox h5 {
  font-size: 16px;
  color: #945f5f;
  font-weight: bold;
  margin-bottom: 0;
}

.whichRoleBox p {
  font-size: 14px;
  color: #945f5f;
}

/** 28-04-2022 **/
.mtminus {
  margin-top: -20px;
  position: relative;
  padding-bottom: 80px;
}

.urDone {
  font-size: 23px;
  color: #4d4e50;
}

.doItLater {
  color: #5e2b97;
  text-decoration: underline;
  font-size: 20px;
}

@media screen and (max-width: 470px) {
  .urDone {
    font-size: 19px;
    color: #4d4e50;
  }
}

/** 02-05-2022 **/
.mtminus {
  min-height: calc(100vh - 258px);
}

.leftArrowSelect .inputIcon select {
  padding-left: 40px;
}

.addNextRole label {
  width: 100%;
  display: inline-block;
}

.circlebox .form-group input[type="radio"] {
  display: block;
  position: absolute;
  opacity: 0;
}

/* 04-05-2022 */
.authorisedacOuter label {
  padding-left: 38px;
  text-indent: -38px;
}

.form-group label.radioLabel {
  margin-right: 15px;
}

.form-group label.radioLabel::before {
  border-radius: 30px;
}

/* menu screen */

.nextBtnBotmWelcome {
  min-height: calc(100vh - 410px);
  position: relative;
  padding-bottom: 65px;
}

.nextBtnBotm {
  min-height: calc(100vh - 360px);
  position: relative;
  padding-bottom: 65px;
}

.fullBtnBottomFix {
  width: 100%;
  position: absolute;
  bottom: 0px;
}

.contentbodymedia .topTileHeading span {
  font-family: "helveticaneuelight";
}

.newAnim span {
  width: 100%;
  height: 100%;
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  transform: scale(1.05);
  border: 2px solid #00838f;

  animation-name: callAnim;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.newAnim span:before {
  content: "";
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 1px solid #00838f;
  position: absolute;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transform: scale(1.1);
}

.newAnim span:after {
  content: "";
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 1px solid #00838f;
  position: absolute;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transform: scale(1.15);
}

.videocallcircle {
  animation-name: callAnim2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes callAnim {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.13);
  }

  100% {
    transform: scale(0.8);
  }
}

@keyframes callAnim2 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

/* add contact */
.label-bold {
  font-weight: bold;
}

.label-disable:before {
  border: 2px solid #b39bcf !important;
}

.raleway_font {
  font-family: "Raleway", sans-serif !important;
}

.manageapps .media-body .subTitleBlog {
  line-height: 1.4;
}

.userprofile1 img {
  object-fit: cover;
}

.relativeLoader {
  display: block;
  width: 100%;
  position: relative;
}

.loaderover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.loaderover:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f6f4f9;
  opacity: 0.8;
}

.flex_loaderbox {
  margin-top: 8px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
}

.flex_loaderbox span {
  padding-left: 15px;
  line-height: normal;
  font-family: "helveticaneuemedium";
}

.flex_loaderbox .spinner-border {
  min-width: 2rem;
}

.button-margin {
  margin-top: 50px;
}

.blogSupportChat span call-us {
  top: calc(20vh - 40%) !important;
  right: calc(90vw - 56%) !important;
}

.blogSupportChat {
  transform: scale(1.8);
  transform-origin: center center;
  -webkit-transform: scale(1.8);
  -webkit-transform-origin: center center;
}

.disable-role {
  opacity: 0.4;
}

.form-group .emgRadio input:checked + label:before {
  background: #ff4c4c !important;
  border-color: #ff4c4c !important;
}

/* .form-group .emgRadio label:before {
  border-color: #ff4c4c !important;
} */

.PhoneInput .PhoneInputInput {
  border: none !important;
  background-color: transparent !important;
}

.PhoneInputCountrySelect[disabled] {
  opacity: 0 !important;
  background-color: #e9ecef;
}

.PhoneInput--disabled {
  background-color: #e9ecef;
}

.help-support {
  position: fixed;
  bottom: 29px;
  text-align: center;
  left: 0;
  right: 0;
}

.help-support img {
  width: 100px;
}

.customtextbbx {
  display: block;
  padding-top: 20px;
}

.customtextbbx h2 {
  text-transform: uppercase;
  font-family: "helveticaneuebold";
}

.customtextbbx ol {
  text-align: left;
  color: #000;
  padding-left: 18px;
  font-family: "HELVETICANEUERegular";
}

.customtextbbx ol li {
  padding-left: 8px;
}

/* .customfilebx {
} */
.customfilebx input[type="file"],
.customfilebx input[type="text"],
.customprofilebox input[type="file"] {
  position: absolute;
  /* width: 100%;
  height: 100%; */
  /* opacity: 0;
  visibility: hidden; */
}

/* .customfilebx label,
.customprofilebox label {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  pointer-events: none;
} */

.file-input-hidden {
  opacity: 0;
  height: 100%;
  bottom: -6px;
  width: 40px;
}

.file-input-hidden-gallery {
  z-index: 1;
  top: 38px;
}

.customprofilegallerybox {
  position: relative;
  overflow: hidden;
}

.customprofilegallerybox input[type="file"] {
  position: absolute;
  border-radius: 30px;
  width: 100%;
  height: calc(100% - 20px);
}

.customprofilegallerybox .boxmediablue {
  pointer-events: none;
}

.certificate-container .preview_img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: calc(100vh - 300px);
}

.radioCircleImages .musicCircle {
  height: 100px;
  width: 100px;
  margin-right: 5px;
}
.radioCircleImages .music-img{
  margin-right: 10px;
}

.radioCircleImages .musicCircleInner {
  height:70px;
  width: 70px;
}

.radioCircleImages .musicImgInner {
  height:60px;
  width: 60px;
}
.radioCircleImages .musicImgInner img{
  width: 100%;
  height: 100%;
}


.manageapps .media.radioCircleImages img.align-self-center {
  height: 80px;
  min-width: 80px;
  width: 80px !important;
  margin: 0px !important;
  border: none !important;
}

.manageapps .radioCircleImages .media-body h5 {
  color: #372251;
  line-height: 22px;
  font-size: 1rem;
}



.checkBoxBlog {
  float: right;
  position: absolute;
  right: 10px;
  top: 25px;
}

.btnVidioCall {
  background: linear-gradient(to right, #35c998 0%, #23b97f 100%);
  border: 1px solid #fff;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  padding: 8px 20px;
  float: right;
  text-align: right;
}

.selectAllCheck {
  float: right;
  display: flex;
  justify-content: right;
  margin: 5px 0;
  font-size: 15px;
}

.selectAllCheck .form-check-label {
  padding-left: 5px;
}

.start-video-call {
  text-align: right;
  float: right;
  height: 50px;
}

.v-hidden {
  visibility: hidden;
}

/* group video call */
/*14-09-2022*/

.ringBg {
  background: #5e2b97;
  padding: 3px 5px !important;
  display: inherit;
  border-radius: 5px;
}

.ringBg a {
  font-size: 12px;
  color: #fff;
}

.messagesBox {
  background: #ead7ff;
  padding: 10px !important;
  font-size: 12px;
  color: #000;
  border-radius: 8px;
}

.bottomBlogBg {
  margin: 0;
  padding: 15px;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
}

.bottomBlogBg .accordion-button {
  background: none !important;
  border: none !important;
  padding: 0px !important;
  box-shadow: none !important;
}

.bottomBlogBg .accordion-item {
  border: none !important;
  background: none !important;
}

.bottomblogbg .accordion-button:focus {
  box-shadow: none !important;
}

.bottomblogbg .accordion-button::after {
  margin-left: 50% !important;
}

.contact-radio {
  width: 52px;
  font-size: 18px;
}

.contact-radio input {
  margin-right: 5px;
}

.annualReminder .form-switch {
  top: 10px;
}

.resendBtn {
  display: inline-block;
  font-size: 12px;
  color: #5e2b97;
  width: 100%;
  font-weight: 600;
}

.topTitleBg {
  background: #5f2b97;
  padding: 5px;
  width: 100%;
  margin-top: 8px;
  float: left;
}

.phoneSettingBox {
  background: #666666;
  padding: 5px;
  display: inline-block;
  width: 100%;
  overflow: auto;
  height: 250px;
}

.phoneSettingBox h2 {
  color: #fff;
}

.homeLeftIcon {
  float: left;
  width: 70px;
}

.homeRightIcon {
  float: right;
  width: 70px;
}

.parent {
  height: 13px;
}

.contentboxgray11 {
  bottom: 13px;
}

.titleHeadBox {
  height: 13px;
}

.titleHeadBox .contentboxgray11 {
  bottom: 13px;
}

.crsrPointer {
  cursor: pointer;
}

.noPointer {
  cursor: default !important;
}

.boxMediaGray {
  background-color: #edebf0;
}

.imagesLabelNo {
  right: 50px;
  background: #4085f9;
  padding: 5px 15px !important;
  color: #fff;
}

.imgNoCircleLable {
  position: absolute;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  background: #4085f9;
  color: #fff;
  font-size: 16px;
  right: 15px;
  text-align: center;
  line-height: 35px;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.2);
  top: 5px;
}

/* Input PIN Pad css */
.pin-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;
}

.inputclass {
  font-size: 24px;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.pin-pad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.buttonpin {
  font-size: 18px;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #d0d0d0;
}

button:active {
  background-color: #a0a0a0;
}

.media-list {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.media-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.media-list-icon-col {
  flex: 0 0 auto;
}

.media-list-content-col {
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 20px;
  flex-wrap: wrap;
}

.media-list li:last-child {
  margin-bottom: 0px;
}

.media-list li .form-switch {
  margin-left: auto;
}

/*--update css 12-04-24---*/
/* .light-btn {
  display: inline-block;
  background: #f6f4f9;
  padding: 8PX 15px;
  border-radius: 14PX;
  min-width: 150PX;
  text-align: center;
  font-size: 18px;
  color: #5e2b97;
  font-family: "helveticaneuebold";
} */

.light-btn {
  display: inline-block;
  background: #5e2b97;
  padding: 8px 15px;
  border-radius: 14px;
  min-width: 150px;
  text-align: center;
  font-size: 18px;
  color: #f6f4f9;
  font-family: "helveticaneuebold";
}

.light-btn:hover {
  color: #fff;
}

.no-bg {
  background: none;
}

/* .light-btn:hover, */
/* .light-btn:focus {
  color: #5e2b97;
} */

.media-box-white {
  padding: 20px;
  border-radius: 49px;
  margin: 0 0 20px;
  background: #fff;
}

.fw_medium {
  font-family: "helveticaneuemedium";
  font-weight: normal;
}

.webappimg-w {
  width: 180px;
}

.text-grey1 {
  color: #69676a;
}

.greentext {
  color: #01d767;
}

.fs-18 {
  font-size: 18px;
}

.fw-20 {
  font-size: 20px;
}

.media-img {
  width: 95px;
  height: 95px;
  border-radius: 50%;
  object-fit: contain;
}

.media-list-img {
  width: 120px;
  height: 120px;
  min-width: 120px;
  object-fit: contain !important;
}

.header-info h4 {
  font-size: 32px;
  font-family: "helveticaneuelight";
}

.header-info h4 span {
  font-size: 32px;
  font-family: "helveticaneuebold";
}

.header-webapp-img {
  max-width: 150px;
  height: auto;
  object-fit: contain;
}

.subTitleBlog.support-text {
  font-size: 14px;
}

.media-list-content-col .greentext {
  margin-right: auto;
}

/*--media query start here--*/

@media screen and (max-width: 460px) {
  .light-btn {
    padding: 8px;
    border-radius: 10px;
    min-width: 110px;
    font-size: 13px;
  }

  .light-btn1 {
    padding: 8px;
    border-radius: 10px;
    min-width: 110px;
    font-size: 13px;
  }

  .media-list li .form-switch {
    margin-top: 15px;
  }

  .media-list-img-col .media-list-img {
    width: 100px;
    height: 120px;
    min-width: 120px;
  }

  .subTitleBlog.support-text {
    font-size: 12px;
  }

  .media-list-content-col .greentext {
    font-size: 15px;
    width: 100%;
    margin-bottom: 10px;
  }

  .media-list-content-col .greentext + .form-switch {
    margin-top: 0;
  }
}

.gap-30 {
  gap: 30px;
}

strong {
  font-family: "helveticaneuebold";
}

/*--media query start here--*/

.pin-code-input {
  width: 100%;
  border-bottom: solid 1px #ccc;
  padding: 5px;
  border-width: 0 0 1px;
  text-align: center;
  color: #5e2b97;
  font-family: "helveticaneuebold";
}

.pinmodal {
  padding: 0 !important;
}

.pinmodal .modal-header {
  font-family: "helveticaneuemedium";
}

.rem-txt-link {
  color: red;
  background-color: transparent;
  /* Set background color to transparent */
  border: none;
  /* Remove border */
  padding: 0;
}

.icon-img {
  cursor: pointer;
}

.phone-set-row {
  display: flex;
  margin-bottom: 20px;
  background: #fff;
  padding: 20px;
  border-radius: 30px;
}

.border-red {
  border-color: red;
}

.phone-set-row h5 {
  color: #372251;
  font-size: 18px;
  font-family: helveticaneuemedium;
}

.phone-set-col-lft {
  flex: 1;
}
.phone-set-col-content {
  flex: 1;
}

.phone-set-img {
  flex: 0 0 60px;
  margin: 0 20px 0 0;
}

@media screen and (max-width: 480px) {
  .phone-set-col-lft {
    flex: 0 0 60%;
  }
  .voicemail_select {
    font-size: 14px;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  /* Optional: adds a semi-transparent background */
  z-index: 9999;
  /* Ensures it overlays other content */
}

.custom-loader {
  width: 80px;
}

.voice-mail-heading span {
  color: #5e2b97;
  font-family: "helveticaneuebold";
  position: relative;
}

.btn-primary-sm,
.btn-primary-sm:hover {
  border-radius: 8px;
  background: #5e2b97;
  border: 0;
  color: #fff;
  padding: 7px 15px;
  font-size: 14px;
  font-family: "helveticaneuebold";
}

.react-dropdown-select {
  border-radius: 8px !important;
  font-size: 14px;
  padding: 4px 10px;
}

.react-dropdown-select-dropdown-handle {
  margin: 0px;
}

.info-box {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 200px;
  top: 20px;
  /* Adjust this according to your design */
  left: 0px;
  /* Adjust this according to your design */
  z-index: 1000;
}

/* Style for the popup container */
.popup-content {
  display: none;
  position: absolute;
  top: 30px;
  right: -14px;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  width: 270px;
  line-height: normal;
  font-size: 13px;
  border: solid 1px #d8d8d8;
  border-radius: 8px;
  color: #000;
  font-family: "helveticaneuelight";
}
.popup-content::before {
  content: "";
  position: absolute;
  top: -7px; /* Position the caret above the popup */
  right: 15px; /* Adjust this to center the caret */
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff; /* Color of the caret */
  z-index: 1; /* Make sure the caret is above the border */
}

.popup-content::after {
  content: "";
  position: absolute;
  top: -8px; /* Position the caret's border above the caret */
  right: 14px; /* Adjust this to align with the caret */
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #d8d8d8; /* Border color of the caret */
  z-index: 0; /* Make sure the border is behind the caret */
}
/* Show the popup on hover */
.info-icon-wrapper:hover .popup-content,
.other-carriers-wrapper:hover .popup-content {
  display: block;
}

.maintance-wrapper {
  height: 100vh;
  background: #fff;
}
.maintance-container {
  padding: 30px;
  max-width: 570px;
  margin: auto;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.contentBody-white {
  background: #fff;
  padding: 0px 2rem 1rem;
}
.contentboxwhite {
  background: #fff;
  border-radius: 20px 20px 0px 0px;
  position: relative;
}
.maintenace-text-col {
  font-size: 12px;
  margin: 30px 0 80px;
  font-family: "helveticaneuemedium";
}

.opt-code-gp input:first-child{
  margin-left: 0px;
}
.opt-code-gp input{
  border: 1px solid #ccc;
  border-radius: 10px;
  height: 3.5rem;
  width: 3.5rem !important;
  margin: 0 10px;
}
.crsrPointer{
  cursor: pointer;
}

.otp-input {
  width: 50px; /* Adjust the width as needed */
  height: 50px; /* Adjust the height as needed */
  text-align: center; /* Center the text horizontally */
  font-size: 24px; /* Adjust the font size as needed */
  border: 1px solid #ccc; /* Add border style */
  border-radius: 4px; /* Optional: add rounded corners */
}

.otp-input:focus {
  outline: none;
  border-color: #007bff; /* Change border color on focus */
}
.form-check .form-check-input {
  margin-left: -1.8em;
}
.form-check {
  padding-left: 1.8em;
}

.form-check.form-switch{
padding-left: 0px;
}
.form-check-input{
  border: 2px solid #5e2b97;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .05), inset 0 -15px 10px -12px rgba(0, 0, 0, .05);
  padding: 10px;
}
.form-check-input:focus {
  border-color: #5e2b97;
  outline: 0;
  box-shadow: none;
}

.phone-set-col-content .form-control:focus {
  border-color: #c7bfd1;

}

/*--media css--*/
@media screen and (max-width:380px) {
  .media-list-content-col {
    flex-flow: column;
    align-items: center;
  }
  .media-list li .media-list-content-col .form-switch{
    margin-left: inherit;
}
.media-list-content-col{
  padding-left: 10px;
}
.media-box-white {
  padding: 20px 15px}

  .setpinbtn {
    padding: 5px;
    font-size: 15px;
}
}
/*--media css--*/


.manageapps .media.radioCircleImages .media-body{
  margin-right: 0px!important;
  }
  border-color: #c7bfd1;

}

/*--media css--*/
@media screen and (max-width:380px) {
  .media-list-content-col {
    flex-flow: column;
    align-items: center;
  }
  .media-list li .media-list-content-col .form-switch{
    margin-left: inherit;
}
.media-list-content-col{
  padding-left: 10px;
}
.media-box-white {
  padding: 20px 15px}

  .setpinbtn {
    padding: 5px;
    font-size: 15px;
}
}
/*--media css--*/


.manageapps .media.radioCircleImages .media-body{
  margin-right: 0px!important;
  }
  .sec-header-inline{
    display: flex;
    align-items: center;
    margin: 0 0 20px;
    flex-wrap: wrap;
  }
.sec-header-inline .sectionHeader{
  margin-right: 15px;
}

  .sectionHeader {
    /* font-size: 16px; */
    color: #69676a;
    font-weight: 600;
    /* margin-bottom: 20px; */
    /* text-transform: lowercase; */
    font-size: 28px;
    margin-bottom:0;
    font-family: "helveticaneuebold";
  }

  .sec-header-inline .greentext{
    font-size: 28px;
    font-weight: 600;
    font-family: "helveticaneuebold";
  }
 


