.pwaPopup {
  background: #5e2b97;
  position: fixed !important;
  z-index: 11;
  left: 0;
  right: 0;
}

.bottomLogo-color {
  background: #5f2c98;
  padding: 20px;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.popup_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Raleway", sans-serif;
  overflow: auto;
  align-items: center;
  height: calc(100vh - 70px);
}

.white_pop_container {
  background: #fff;
  border-radius: 56px;
  text-align: center;
  width: 90%;
  padding: 30px;
  font-size: 18px;

  p {
    font-weight: 500;
  }
}

.btn_fill {
  color: #fff;
  background-color: #bd8ef2;
  border: solid 1px #707070;
  height: 52px;
  border-radius: 26px;
  font-size: 1rem;
  padding: 0px 15px;
  min-width: 130px;
  font-weight: 800;
}

.btn_outline {
  color: #4e267c;
  background-color: transparent;
  border: solid 1px #707070;
  height: 52px;
  border-radius: 26px;
  font-size: 1rem;
  padding: 0px 15px;
  min-width: 130px;
  font-weight: 800;
}

.pop_heading {
  font-size: 2rem;
  color: #4e277d;

  .boldtext {
    font-weight: 900;
  }
}

// button+button {
//   margin-left: 20px;
// }

.btn_fullwidth button {
  flex: 1;
}

// .pop_heading{
//     font-size: 40px;
//     color: #4E277D;
//     line-height: 42px;
// }
// .pop_heading .boldtext{
// font-weight: 900;
// }

.arrowimg {
  display: inline-block;
  vertical-align: bottom;
}
