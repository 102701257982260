body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.align-self-center {
  object-fit: cover;
}

#span {
  color: red;
}

.rdtOpen input {
  display: none !important;
}

/* .img-full-height {
  height: 360px !important;
} */

.full-view-header > .contentBody {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.error {
  color: red;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #8655be !important;
}

.callWaiting {
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: -1;
}
.callJoining {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.photo-description {
  overflow-wrap: break-word;
}

/* .react-pdf__Document {
  overflow-x: scroll;
} */

/* .birthdayPopup {

} */
.reminderInput .form-control {
  text-align: left;
  font-size: 2rem !important;
  font-weight: 600;
  border-bottom: 1px solid #c7bfd1;
  padding: 10px;
  height: 52px;
}

.timeBlog {
  width: 46%;
  float: left;
}
.ml5 {
  margin-left: 2%;
}
/* Web url design */
.selecturl-opt {
  margin-top: 20px;
}
.selecturl-opt .css-qbdosj-Input {
  display: none;
}
.selecturl-opt .css-13cymwt-control,
.selecturl-opt .css-13cymwt-control,
.selecturl-opt .css-t3ipsp-control {
  border-radius: 20px;
  border-color: transparent;
  padding: 5px 10px;
  width: 100%;
  box-shadow: none;
}
.selecturl-opt .css-1p3m7a8-multiValue {
  background-color: #8655be;
  border-radius: 20px;
  color: #fff;
  padding: 0 7px;
  font-size: 17px;
}
.selecturl-opt .css-1p3m7a8-multiValue > div {
  color: #fff;
}
.selecturl-opt .css-1sdqb9r-control .css-1xc3v61-indicatorContainer {
  color: #666;
}

.selecturl-opt .css-1nmdiq5-menu {
  border-radius: 20px;
  box-shadow: none;
  padding: 10px 20px;
  position: relative;
}
.css-syji7d-Group .css-d7l1ni-option,
.css-syji7d-Group .css-10wo9uf-option {
  padding: 4px 10px;
}
.css-syji7d-Group {
  font-size: 16px;
}
.selecturl-opt .css-jtaw72-group {
  color: #595656;
  font-family: "helveticaneuebold";
  padding-left: 0;
}
.selecturl-opt input[type="checkbox"] {
  width: 16px;
  height: 16px;
  border-color: #8655be;
  margin-right: 10px;
}
.selecturl-opt input[type="checkbox"]:checked {
  background: red;
}

.contactNoTabsUI.nav-tabs { display: flex;
  justify-content: space-between; }
.contactNoTabsUI .nav-item {
    flex: 1; 
    text-align: center;
  }
  .contactNoTabsUI .nav-link {
    font-size: 18 px; 
    padding: 10px 15px; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
.contactNoTabsUI.nav-tabs li { width: 50%; }
.contactNoTabsUI.nav-tabs .nav-item .nav-link, .contactNoTabsUI.nav-tabs .nav-link { width: 100%; background: transparent; margin-right: 10px; border: 1px solid #9b9b9b; color: #9b9b9b; border-radius: 50px; padding: 8px 30px; }  
.contactNoTabsUI.nav-tabs .nav-item.show .nav-link, .contactNoTabsUI.nav-tabs .nav-link.active { background: #5e2b97; border-color: #5e2b97; color: #fff; }  
.contactNoTabsUI.nav-tabs .nav-item .nav-link img, .contactNoTabsUI.nav-tabs .nav-link img { margin-top: -2px; }  
.contactNoTabsUI.nav-tabs .nav-item.show .nav-link img, .contactNoTabsUI.nav-tabs .nav-link.active img { filter: brightness(100); }  

.contactNoTabsUI.nav-tabs li:first-child .nav-link { border-radius: 50px 0px 0 50px; }
.contactNoTabsUI.nav-tabs li:last-child .nav-link { border-radius: 0 50px 50px 0; }
